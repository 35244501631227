import { useState } from "react";
import { NavLink } from "react-router-dom";

import { ItemMenu } from "../style";

import dashboard from "../../../assets/Icons Sidebar/icon-dashboard.svg";
import classificacao from "../../../assets/Icons Sidebar/icon-classificacao.svg";
import tag from "../../../assets/Icons Sidebar/icon-tag.svg";
// import mapa from "../../../assets/Icons Sidebar/icon-map.svg";
import { sessionStorageDecrypt } from "../../../utils/crypto";
import { AnaliseItemMenu } from "./AnaliseItemMenu";
import { RelatorioItemMenu } from "./RelatorioItemMenu";

// Opções do Menu usados no Sidebar do Armazém

export default function MenuArmazem({ setShowMenu, selected }) {
  const [portalAntigo, setPortalAntigo] = useState(false);
  const infoCheckPhp = encodeURIComponent(sessionStorage.getItem("php"));
  const urlMapa = sessionStorageDecrypt("urlMapa");

  return (
    <>
      <ItemMenu>
        <NavLink
          to="/armazem"
          end
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={dashboard} alt="" className="menu_img" />
          Dashboard
        </NavLink>
      </ItemMenu>
      <AnaliseItemMenu setShowMenu={setShowMenu} selected={selected} />
      <RelatorioItemMenu setShowMenu={setShowMenu} selected={selected} />
      <ItemMenu>
        <NavLink
          to="armazem/tags"
          className="menu_link"
          onClick={() => setShowMenu(false)}
          style={({ isActive }) => (isActive ? selected : undefined)}
        >
          <img src={tag} alt="" className="menu_img" />
          Histórico de TAG
        </NavLink>
      </ItemMenu>

      <button
        className="portalAntigo"
        onClick={() => setPortalAntigo(!portalAntigo)}
      >
        <img src={classificacao} alt="" className="menu_img" />
        Outros Recursos
      </button>

      {portalAntigo && (
        <div className="subMenu">
          <a
            href={`${urlMapa}/classificacao.php?storagephp=${infoCheckPhp}`}
            className="menu_link_antigo"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setPortalAntigo(!portalAntigo);
              setShowMenu(false);
            }}
          >
            {/* <img src={classificacao} alt="" className="menu_img" /> */}
            Classificação Café
          </a>
          <a
            href={`${urlMapa}/mapa.php?storagephp=${infoCheckPhp}`}
            className="menu_link_antigo"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setPortalAntigo(!portalAntigo);
              setShowMenu(false);
            }}
          >
            {/* <img src={mapa} alt="" className="menu_img" /> */}
            Mapa do Armazém
          </a>
        </div>
      )}
    </>
  );
}
