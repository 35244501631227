import React from "react";
import { ModalOverlay, ModalContent } from "./style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerModal = ({ isOpen, onClose, onChange, startDate, endDate }) => {
  if (!isOpen) return null;
  console.log(startDate, endDate);

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          dateFormat="dd/MM/yyyy"
        />
      </ModalContent>
    </ModalOverlay>
  );
};

export default DatePickerModal;
