import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";

export const Container = styled.div`
  .fazendas {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
  }
  .TabsRoot {
    display: flex;
    flex-direction: column;
  }

  .TabsList {
    display: inline-flex;
    gap: 1rem;
    padding: 0;
    border-bottom: 1px solid rgb(202, 202, 202);
  }

  .TabsTrigger {
    position: relative;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
  }

  .TabsTrigger:hover:not([data-state="active"]) {
    opacity: 0.8;
  }

  .TabsTrigger[data-state="active"] {
    opacity: 1;
    color: var(--blue-a18);
  }

  .TabsTrigger::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -1px;
    width: 0%;
    height: 2px;
    background-color: var(--blue-a18);
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  .TabsTrigger[data-state="active"]::after {
    width: 100%;
  }
`;

export const Cards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 colunas fixas */
  gap: 1rem;
  padding: 1rem 0;
`;

export const ButtonDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue-a18);
  background-color: var(--blue-a18);
  border-radius: 0.25rem;
  justify-self: center;
  padding: 2px;
  margin: auto;
`;

export const StyledOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const StyledContent = styled(Dialog.Content)`
  background-color: var(--blue-a18);
  border-radius: 6px;
  box-shadow: var(--shadow-6);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 90vh;
  overflow: auto;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }
`;

export const StyledTitle = styled(Dialog.Title)`
  margin: 0;
  font-weight: 500;
  color: var(--blue-a1);
  font-size: 17px;
`;

export const StyledDescription = styled(Dialog.Description)`
  margin: 10px 0 20px;
  color: var(--gray-c2);
  font-size: 15px;
  line-height: 1.5;
`;

export const CloseButton = styled(Dialog.Close)`
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--gray-c2);
  cursor: pointer;

  &:hover {
    background-color: var(--gray-c3);
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--blue-emsoft);
  }
`;
