import styled from "styled-components";

export const Container = styled.div`
  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid var(--blue-a16);

    /* Remove a borda superior de elementos pares */
    :nth-child(even) {
      border-top: none;
    }

    :nth-child(even) {
      border-bottom: none;
    }

    p {
      color: var(--blue-a1);
    }

    .label {
      color: var(--gray-c3);
    }
  }

  /* Aplica o border-radius nas bordas superiores da primeira .details */
  .details:first-of-type {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }

  .last {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .wrapperOpe {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    border: 1px solid var(--blue-a16);
    border-radius: 0.5rem;

    div {
      padding: 8px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 1.2rem;
    }

    /* Aplica borda inferior apenas no primeiro <p> dentro da .wrapperOpe */
    > p:first-child {
      border-bottom: 1px solid var(--blue-a16);
      padding: 4px;
    }

    p {
      color: var(--blue-a1);
      text-align: center;
    }

    .label {
      color: var(--gray-c3);
      text-align: start;
    }
  }
`;
