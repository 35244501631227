import { Route } from "react-router-dom";
import RastreioLotes from "../../Modules/Armazem/Analises/RastreioLotes/RastreioLotes";
import { InstrucaoServicos } from "../../Modules/Armazem/Analises/InstrucaoServicos/InstrucaoServicos";
import { ProcessosFinalizados } from "../../Modules/Armazem/Analises/ProcessosFinalizados/ProcessosFinalizados";
import { PesagemRodoviaria } from "../../Modules/Armazem/Analises/PesagemRodoviaria/PesagemRodoviaria";
import { CargaDescarga } from "../../Modules/Armazem/Analises/CargaDescarga/CargaDescarga";
import { ServicosArmazem } from "../../Modules/Armazem/Analises/ServicosArmazem/ServicosArmazem";
import { Entradas } from "../../Modules/Armazem/Analises/Entradas/Entradas";
import { Saidas } from "../../Modules/Armazem/Analises/Saidas/Saidas";

export function AnaliseRoute() {
  return (
    <>
      <Route
        element={<CargaDescarga />}
        path="armazem/analises/carga-descarga"
        exact
      />
      <Route element={<Entradas />} path="armazem/analises/entradas" exact />
      <Route element={<Saidas />} path="armazem/analises/saidas" exact />
      <Route
        element={<ServicosArmazem />}
        path="armazem/analises/servicos-armazem"
        exact
      />
      <Route
        element={<PesagemRodoviaria />}
        path="armazem/analises/pesagem-rodoviaria"
        exact
      />
      <Route
        element={<ProcessosFinalizados />}
        path="armazem/analises/finalizados"
        exact
      />
      <Route
        element={<InstrucaoServicos />}
        path="armazem/analises/isd"
        exact
      />
      <Route
        element={<RastreioLotes />}
        path="armazem/analises/rastreio"
        exact
      />
    </>
  );
}
