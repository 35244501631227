import { useState } from "react";
import { NavLink } from "react-router-dom";
import relatorios from "../../../assets/Icons Sidebar/icon-relatorios.svg";

import { Icon, ItemMenu } from "../style";

export function RelatorioItemMenu({ setShowMenu, selected }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ItemMenu className="wrapper" isOpen={isOpen}>
      <div onClick={() => setIsOpen(!isOpen)}>
        <img src={relatorios} alt="" className="menu_img" />
        Relatórios
        <Icon color="#fff" isOpen={isOpen} $porcentLeft="28%" />
      </div>
      {isOpen && (
        <div className="links">
          <NavLink
            to="armazem/relatorios/extrato-cafe"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Extrato de café
          </NavLink>
          <NavLink
            to="armazem/relatorios/estoque-fisico"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Estoque físico
          </NavLink>
          <NavLink
            to="armazem/relatorios/boletim"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Boletim movimentação
          </NavLink>
        </div>
      )}
    </ItemMenu>
  );
}
