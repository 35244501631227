import { useState, useMemo, useCallback } from "react";
import moment from "moment";
import {
  Container,
  Empty,
  HeaderSection,
  Table,
  TableBottom,
  TableSearch,
  Tag,
} from "./style";
import { TableHead } from "./TableHead";
import {
  calcularSaldoSacasTotal,
  calcularSaldoPesoTotal,
  generateId,
} from "../../../../../../../utils/functions";
import { ListFilter } from "lucide-react";

export function TableFazenda({ data }) {
  const [colunaOrdenada, setColunaOrdenada] = useState(null);
  const [direcao, setDirecao] = useState("asc");
  const [termoBusca, setTermoBusca] = useState(""); // Estado para a busca

  // Memoiza o processamento dos registros
  const registrosProcessados = useMemo(() => {
    return data.registros
      .filter(
        (registro) =>
          registro.FLG_OPERACAO !== "A" && registro.FLG_OPERACAO !== "X"
      )
      .map((registro) => ({
        ...registro,
        movimento: definirMovimento(registro.FLG_OPERACAO),
        id: generateId(),
      }));
  }, [data.registros]);

  function definirMovimento(flag) {
    const tiposMovimento = {
      E: "ENTRADA",
      R: "RESULTADO",
      S: "EMBARQUE",
      T: "TRANSFERÊNCIA",
      I: "DESPEJO",
      P: "PICOTE",
    };
    return tiposMovimento[flag] || "OUTRO";
  }

  // Memoiza a filtragem dos registros
  const registrosFiltrados = useMemo(() => {
    return termoBusca.trim() !== ""
      ? registrosProcessados.filter((item) =>
          item.movimento.toLowerCase().includes(termoBusca.toLowerCase())
        )
      : registrosProcessados;
  }, [termoBusca, registrosProcessados]);

  // Memoiza a ordenação dos registros
  const registrosOrdenados = useMemo(() => {
    if (!colunaOrdenada) return registrosFiltrados;

    return [...registrosFiltrados].sort((a, b) => {
      let valorA = a[colunaOrdenada];
      let valorB = b[colunaOrdenada];

      if (colunaOrdenada === "DAT_OPERACAO") {
        valorA = moment(valorA, "YYYY-MM-DD").valueOf();
        valorB = moment(valorB, "YYYY-MM-DD").valueOf();
      }

      // Se os valores forem null ou undefined, tratamos como string vazia para evitar erro no localeCompare
      valorA = valorA ?? "";
      valorB = valorB ?? "";

      if (typeof valorA === "number" && typeof valorB === "number") {
        return direcao === "asc" ? valorA - valorB : valorB - valorA;
      }

      return direcao === "asc"
        ? String(valorA).localeCompare(String(valorB))
        : String(valorB).localeCompare(String(valorA));
    });
  }, [colunaOrdenada, direcao, registrosFiltrados]);

  // Memoiza a função de ordenação para evitar recriação desnecessária
  const ordenarPor = useCallback(
    (coluna) => {
      if (colunaOrdenada === coluna) {
        setDirecao(direcao === "asc" ? "desc" : "asc");
      } else {
        setColunaOrdenada(coluna);
        setDirecao("asc");
      }
    },
    [colunaOrdenada, direcao]
  );

  // 🔹 Agrupar registros por embalagem
  const { totaisEntrada, totaisEmbarque } = useMemo(() => {
    return data.registros.reduce(
      (acc, registro) => {
        if (!registro.NOM_EMBALAGEM) return acc;

        const movimento = registro.FLG_OPERACAO;
        const embalagem = registro.NOM_EMBALAGEM;
        const sacas = registro.NUM_SACAS || 0;
        const peso = registro.NUM_PESO || 0;

        if (movimento === "E") {
          if (!acc.totaisEntrada[embalagem]) {
            acc.totaisEntrada[embalagem] = { sacas: 0, peso: 0 };
          }
          acc.totaisEntrada[embalagem].sacas += sacas;
          acc.totaisEntrada[embalagem].peso += peso;
        }

        if (movimento === "S") {
          if (!acc.totaisEmbarque[embalagem]) {
            acc.totaisEmbarque[embalagem] = { sacas: 0, peso: 0 };
          }
          acc.totaisEmbarque[embalagem].sacas += sacas;
          acc.totaisEmbarque[embalagem].peso += peso;
        }

        return acc;
      },
      { totaisEntrada: {}, totaisEmbarque: {} }
    );
  }, [data.registros]);

  return (
    <Container>
      <HeaderSection>
        <h2>{data.NOM_FAZE}</h2>
        <Tag $bgColor="#009FE3">
          <span className="small">Saldo anterior (sacas)</span>
          <span className="value">
            {calcularSaldoSacasTotal(data.registros, "A").toLocaleString(
              "pt-BR"
            )}
          </span>
        </Tag>

        {/* Input de busca */}
        <TableSearch>
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Filtrar por movimento"
            value={termoBusca}
            onChange={(e) => setTermoBusca(e.target.value)}
          />
          <ListFilter size={20} />
        </TableSearch>
      </HeaderSection>

      {registrosOrdenados.length === 0 && <Empty>Sem movimentações</Empty>}

      {registrosOrdenados.length > 0 && (
        <>
          <Table>
            <TableHead
              ordenarPor={ordenarPor}
              colunaOrdenada={colunaOrdenada}
              direcao={direcao}
            />
            <tbody>
              {registrosOrdenados.map((item) => (
                <tr key={item.id}>
                  <td>{item.movimento}</td>
                  <td>{item.NUM_DOC}</td>
                  {item.movimento === "PICOTE" ? (
                    <td>{item.NUM_NOTA_PIC}</td>
                  ) : item.NUM_NOTA ? (
                    <td>
                      {`${item.NUM_NOTA}/${
                        item.NOM_SERIE ? item.NOM_SERIE : "000"
                      }`}
                    </td>
                  ) : (
                    <td>{item.NOM_SERIE ? item.NOM_SERIE : ""}</td>
                  )}
                  <td>{item.NOM_OS}</td>
                  <td>{item.NOM_REM}</td>
                  <td>{moment(item.DAT_OPERACAO).format("DD/MM/YYYY")}</td>
                  <td>{item.COD_LOTE}</td>
                  <td>{item.NUM_SACAS.toLocaleString("pt-BR")}</td>
                  <td>
                    {item.NUM_PESO.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td>{item.NOM_EMBALAGEM ? item.NOM_EMBALAGEM : ""}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <TableBottom>
            <div>
              <p>
                Total de entrada:{" "}
                {calcularSaldoSacasTotal(data.registros, "E").toLocaleString(
                  "pt-BR"
                )}{" "}
                sacas /{" "}
                {calcularSaldoPesoTotal(data.registros, "E").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
            <div className="totais-embalagens">
              <p>Total de entrada por embalagem:</p>
              {Object.entries(totaisEntrada).map(([embalagem, totais]) => (
                <p key={embalagem}>
                  {embalagem}: {totais.sacas.toLocaleString("pt-BR")} sacas /{" "}
                  {totais.peso.toLocaleString("pt-BR")} kgs
                </p>
              ))}
            </div>
            <div>
              <p>
                Total de embarques:{" "}
                {calcularSaldoSacasTotal(data.registros, "S").toLocaleString(
                  "pt-BR"
                )}{" "}
                sacas /{" "}
                {calcularSaldoPesoTotal(data.registros, "S").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
            <div className="totais-embalagens">
              <p>Total de embarques por embalagem:</p>
              {Object.entries(totaisEmbarque).map(([embalagem, totais]) => (
                <p key={embalagem}>
                  {embalagem}: {totais.sacas.toLocaleString("pt-BR")} sacas /{" "}
                  {totais.peso.toLocaleString("pt-BR")} kgs
                </p>
              ))}
            </div>
            <div>
              <p>
                Total de transferências:{" "}
                {calcularSaldoSacasTotal(data.registros, "T").toLocaleString(
                  "pt-BR"
                )}{" "}
                sacas /{" "}
                {calcularSaldoPesoTotal(data.registros, "T").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
            <div>
              <p>
                Total de despejos:{" "}
                {calcularSaldoSacasTotal(data.registros, "I").toLocaleString(
                  "pt-BR"
                )}{" "}
                sacas /{" "}
                {calcularSaldoPesoTotal(data.registros, "I").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
            <div>
              <p>
                Total de resultados:{" "}
                {calcularSaldoSacasTotal(data.registros, "R").toLocaleString(
                  "pt-BR"
                )}{" "}
                sacas /{" "}
                {calcularSaldoPesoTotal(data.registros, "R").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
            <div>
              <p>
                Total de picote:{" "}
                {calcularSaldoPesoTotal(data.registros, "P").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
            <div className="atual">
              <p>
                Saldo atual:{" "}
                {calcularSaldoSacasTotal(data.registros, "X").toLocaleString(
                  "pt-BR"
                )}{" "}
                sacas /{" "}
                {calcularSaldoPesoTotal(data.registros, "X").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
          </TableBottom>
        </>
      )}
    </Container>
  );
}
