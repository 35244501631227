import { ArrowDownUp, MoveDown, MoveUp } from "lucide-react";

export function TableHead({ ordenarPor, colunaOrdenada, direcao }) {
  return (
    <thead>
      <tr>
        <th onClick={() => ordenarPor("NUM_IS")}>Guia </th>
        <th onClick={() => ordenarPor("DAT_ABERTURA")}>Data abertura </th>
        <th onClick={() => ordenarPor("DAT_FECHAMENTO")}>Data fechamento </th>
        <th onClick={() => ordenarPor("NOM_TIPO_IS")}>Tipo de guia </th>
        <th onClick={() => ordenarPor("SACAS_ENTRADA")}>Scs. despejo </th>
        <th onClick={() => ordenarPor("SACAS_SAIDA")}>Scs. resultado </th>
        <th onClick={() => ordenarPor("PESO_ENTRADA")}>Peso despejo </th>
        <th onClick={() => ordenarPor("PESO_SAIDA")}>Peso resultado </th>
        <th onClick={() => ordenarPor("NUM_PEDRA")}>Pedra </th>
        <th onClick={() => ordenarPor("NUM_PO")}>Pó </th>
        <th onClick={() => ordenarPor("NUM_PO_ATM")}>Amostra </th>
      </tr>
    </thead>
  );
}
