import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

export function ChartSacasArmazem({ dataApi, armazensDisponiveis }) {
  // Criar um objeto para armazenar a soma das sacas por armazém
  const sacasPorArmazem = dataApi.reduce((acc, item) => {
    acc[item.S_ARMAZEM] = (acc[item.S_ARMAZEM] || 0) + item.N_SACAS_ARM;
    return acc;
  }, {});

  // Criar um array ordenado conforme os armazéns disponíveis
  const totalSacasArmazem = armazensDisponiveis.map(
    (armazem) => sacasPorArmazem[armazem] || 0
  );

  const labels = armazensDisponiveis;

  const data = {
    labels,
    datasets: [
      {
        label: "Sacas",
        data: totalSacasArmazem,
        backgroundColor: "#047857",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Por Armazéns",
        color: "#fff",
      },
      legend: {
        display: false, // Desativa a legenda
      },
      datalabels: {
        display: true,
        color: "#fff",
        font: {
          weight: "bold",
        },
        anchor: "end",
        align: "start",
        offset: -10,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw} `,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#FFF",
        },
      },
      y: {
        ticks: {
          color: "#FFF",
          stepSize: 500,
          callback: (val) => `${val} `,
        },
      },
    },
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar
        data={data}
        options={{
          ...options,
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
}
