import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  background: ${({ bgColor }) => bgColor || "#fff"};
  color: var(--blue-a1);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 168px;
  height: 124px; /* Define uma altura fixa */

  /* Posiciona o span no canto superior direito */
  span {
    position: absolute;
    top: 16px;
    right: 14px;
  }
  .saldo {
    font-size: 0.75rem; /* Texto pequeno */
    color: rgb(199, 199, 199); /* Cinza claro */
  }

  .service {
    font-size: 1rem;
    font-weight: 500;
  }

  .value {
    font-size: 1.5rem;
    font-weight: bold;
  }

  div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.5rem;

    p:last-child {
      margin-bottom: 0.25rem;
    }
  }
`;
