import { useMemo } from "react";

import { Container } from "./style";

import { useSearchParams } from "react-router-dom";

import { useServicoArmazem } from "./useServicoArmazem";

import { generateId } from "../../../../utils/functions";

import { Tabs } from "radix-ui";

import { Armazem } from "./components/Armazens";
import { Filter } from "./components/Filter";
import Loading from "../../../../components/Layouts/Loading/Loading";
import { ChartArea, Table2 } from "lucide-react";
import { Charts } from "./components/Charts";

export function ServicosArmazem() {
  const [searchParams] = useSearchParams();
  const { data, isLoading, error } = useServicoArmazem();

  // Lista de armazéns disponíveis, ordenada alfabeticamente
  const ARMAZENS_DISPONIVEIS = useMemo(() => {
    if (!data || isLoading || error) return [];

    // Criar um Set para garantir valores únicos e ordenar depois
    return Array.from(new Set(data.map((item) => item.S_ARMAZEM))).sort();
  }, [data, isLoading, error]);

  // Transformar os registros agrupados no formato desejado
  const registrosAgrupados = useMemo(() => {
    if (!data || isLoading || error) return [];

    // Agrupa os dados por armazém
    const agrupados = data.reduce((acc, item) => {
      const armazem = item.S_ARMAZEM;

      // Calcula a porcentagem de conclusão
      const { N_BAGS_ARM, N_BAGS_PEND_ARM } = item;
      const conclusao =
        N_BAGS_ARM && N_BAGS_ARM > 0
          ? `${Math.round(
              ((N_BAGS_ARM - N_BAGS_PEND_ARM) / N_BAGS_ARM) * 100
            )}%`
          : "0%";

      if (!acc[armazem]) {
        acc[armazem] = [];
      }

      acc[armazem].push({
        ...item,
        S_CONCLUSAO: conclusao, // Adiciona a porcentagem de conclusão
      });

      return acc;
    }, {});

    // Converte o objeto agrupado para um array de objetos
    return Object.keys(agrupados).map((armazem) => ({
      armazem,
      id: generateId(),
      dataArmazem: agrupados[armazem],
    }));
  }, [data, isLoading, error]);

  // Pega os armazéns da URL, se não tiver nenhum, exibe todos por padrão
  const armazensSelecionados = searchParams.get("armazem")
    ? searchParams.get("armazem").split(",").sort() // Garante que a lista esteja sempre ordenada
    : ARMAZENS_DISPONIVEIS;

  const armazensOrdenado = useMemo(() => {
    return registrosAgrupados.sort((a, b) =>
      a.armazem.localeCompare(b.armazem)
    );
  }, [registrosAgrupados]);

  const registrosFiltrados = useMemo(() => {
    return armazensOrdenado.filter((armazem) =>
      armazensSelecionados.includes(armazem.armazem)
    );
  }, [armazensOrdenado, armazensSelecionados]);

  // Lê os filtros da URL
  const filtros = {
    servico: searchParams.get("servico") || "",
    documento: searchParams.get("documento") || "",
    sacasMin: searchParams.get("sacasMin") || "",
    sacasMax: searchParams.get("sacasMax") || "",
    remocMin: searchParams.get("remocMin") || "",
    remocMax: searchParams.get("remocMax") || "",
    totalBgMin: searchParams.get("totalBgMin") || "",
    totalBgMax: searchParams.get("totalBgMax") || "",
    totalBgPenMin: searchParams.get("totalBgPenMin") || "",
    totalBgPenMax: searchParams.get("totalBgPenMax") || "",
    emb: searchParams.get("emb") || "",
    operador: searchParams.get("operador") || "",
    status: searchParams.get("status") || "",
    conclusao: searchParams.get("conclusao") || "",
    cliente: searchParams.get("cliente") || "",
  };

  return (
    <Container>
      <h3>Programação de serviços por armazém</h3>

      {isLoading && <Loading />}

      {data && !isLoading && !error && (
        <>
          <Tabs.Root className="TabsRoot" defaultValue="tab1">
            <Tabs.List
              className="TabsList"
              aria-label="Análise de serviços por armazém"
            >
              <Tabs.Trigger className="TabsTrigger" value="tab1">
                <ChartArea size={20} />
                Gráficos
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="tab2">
                <Table2 size={20} />
                Tabelas
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="tab1">
              <Charts
                armazensDisponiveis={ARMAZENS_DISPONIVEIS}
                armazensAgrupados={registrosAgrupados}
                armazensData={data}
              />
            </Tabs.Content>
            <Tabs.Content value="tab2">
              <Filter
                filtros={filtros}
                armazensSelecionados={armazensSelecionados}
                armazensDisponiveis={ARMAZENS_DISPONIVEIS}
              />
              <div className="wrapper">
                {registrosFiltrados.map((armazem) => (
                  <Armazem
                    key={armazem.id}
                    armazem={armazem}
                    filtros={filtros}
                  />
                ))}
              </div>
            </Tabs.Content>
          </Tabs.Root>
        </>
      )}

      {error && !isLoading && <div>Erro ao carregar os dados!</div>}
    </Container>
  );
}
