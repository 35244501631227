import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

export function ChartClient({ dataApi }) {
  const fisicaData = dataApi.map((item) => item.N_QUANT_FISICO);
  const juridicaData = dataApi.map((item) => item.N_QUANT_JURIDICO);

  const labels = ["Até 200", "201 a 500", "501 a 1000", "1001 a 2000", "2001+"];

  const data = {
    labels,
    datasets: [
      {
        label: "Física",
        data: fisicaData,
        backgroundColor: "#047857",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
      {
        label: "Jurídica",
        data: juridicaData,
        backgroundColor: "#003d73",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
    ],
  };

  const options = {
    responsive: true,

    plugins: {
      title: {
        display: true,
        text: "Clientes",
        color: "#fff",
      },
      legend: {
        position: "bottom",
        labels: {
          color: "#FFF",
        },
      },
      datalabels: {
        display: true,
        color: "#fff",
        font: {
          weight: "bold",
        },
        anchor: "end",
        align: "start",
        offset: -10,
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw} clientes`,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#FFF",
        },
      },
      y: {
        ticks: {
          color: "#FFF",
          stepSize: 1,
          callback: (val) => `${val} clientes`,
        },
      },
    },
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar
        data={data}
        options={{
          ...options,
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
}
