import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

export function ChartSacasServico({ dataApi }) {
  // Criar um Set para garantir que os serviços são únicos e depois transformá-lo em array
  const servicosDisponiveis = [
    ...new Set(dataApi.map((item) => item.S_SERVICO)),
  ];

  // Criar um objeto para armazenar a soma das sacas por serviço
  const totalSacasServico = servicosDisponiveis.map((servico) =>
    dataApi
      .filter((item) => item.S_SERVICO === servico)
      .reduce((acc, curr) => acc + curr.N_SACAS_ARM, 0)
  );

  const data = {
    labels: servicosDisponiveis,
    datasets: [
      {
        label: "Sacas",
        data: totalSacasServico,
        backgroundColor: "#003d73",
        datalabels: {
          anchor: "end",
          align: "start",
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Por Serviços",
        color: "#fff",
      },
      legend: {
        display: false, // Remove a legenda
      },
      datalabels: {
        display: true,
        color: "#fff",
        font: {
          weight: "bold",
        },
        anchor: "end",
        align: "start",
        offset: -10,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw} `,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#FFF",
        },
      },
      y: {
        ticks: {
          color: "#FFF",
          stepSize: 500,
          callback: (val) => `${val} `,
        },
      },
    },
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar
        data={data}
        options={{
          ...options,
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
}
