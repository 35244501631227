import { SelectedContext } from "./SelectedContext";
import { useContext, useState, useEffect } from "react";
import { getDadosProdutores } from "../../../../../../services/apiPortalProdutor";
import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";

export const SelectedProvider = ({ children }) => {
  const { dono, userAuth, password } = useContext(AuthContext);
  const [produtorSelected, setProdutorSelected] = useState(null);
  const [date, setDate] = useState({ firstDate: null, lastDate: null });

  const [produtores, setProdutores] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Função para manipular a seleção do produtor
  function handleSelectProdutor(selectedOptions) {
    setProdutorSelected(selectedOptions);
  }

  useEffect(() => {
    async function fetch() {
      const res = await getDadosProdutores(dono, userAuth, password);
      const produtoresData = res.map((item) => ({
        value: `${item.COD_CLIENTE}-${item.NOM_CLIENTE} (${item.NUM_CPFCGC})`,
        label: `${item.COD_CLIENTE}-${item.NOM_CLIENTE} (${item.NUM_CPFCGC})`,
        cpf: item.NUM_CPFCGC,
        nome: item.NOM_CLIENTE,
      }));
      setProdutores(produtoresData);
    }
    fetch();
  }, [dono, userAuth, password]);

  return (
    <SelectedContext.Provider
      value={{
        produtorSelected,
        date,
        setDate,
        produtores,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        handleSelectProdutor,
      }}
    >
      {children}
    </SelectedContext.Provider>
  );
};
