import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue-a18);
  background-color: var(--blue-a18);
  border-radius: 0.25rem;
  justify-self: center;
  padding: 2px;
  margin: auto;
`;

export const StyledOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const StyledContent = styled(Dialog.Content)`
  background-color: var(--blue-a18);
  border-radius: 6px;
  box-shadow: var(--shadow-6);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 90vh;
  overflow: auto;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }
`;

export const StyledTitle = styled(Dialog.Title)`
  margin: 0;
  font-weight: 500;
  color: var(--blue-a1);
  font-size: 17px;
`;

export const StyledDescription = styled(Dialog.Description)`
  margin: 10px 0 20px;
  color: var(--gray-c2);
  font-size: 15px;
  line-height: 1.5;
`;

export const CloseButton = styled(Dialog.Close)`
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--gray-c2);
  cursor: pointer;

  &:hover {
    background-color: var(--gray-c3);
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--blue-emsoft);
  }
`;
