import { useSearchParams, useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import { FilterIcon, FilterX, X } from "lucide-react";
import {
  Button,
  CloseButton,
  Container,
  StyledContent,
  StyledOverlay,
  Tag,
} from "./style";

export function Filter({ filtros, armazensSelecionados, armazensDisponiveis }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const limparFiltros = () => {
    navigate(window.location.pathname);
  };

  const atualizarFiltro = (key, value) => {
    const newParams = new URLSearchParams(searchParams);

    if (value === "" || value.length === 0) {
      newParams.delete(key);
    } else {
      newParams.set(key, Array.isArray(value) ? value.join(",") : value);
    }

    setSearchParams(newParams);
  };

  const toggleArmazem = (armazem) => {
    let novaSelecao = armazensSelecionados.includes(armazem)
      ? armazensSelecionados.filter((a) => a !== armazem)
      : [...armazensSelecionados, armazem].sort();

    if (novaSelecao.length === 0) {
      novaSelecao = armazensDisponiveis;
    }

    atualizarFiltro("armazem", novaSelecao);
  };

  const isValue = Object.values(filtros).some(
    (valor) => valor !== "" && valor !== null && valor !== undefined
  );

  const tagsNameValue = {
    sacas: "Sacas",
    remoc: "Remoção",
    totalBg: "Total bags",
    totalBgPen: "Total bags pend.",
  };

  // Gerar um array de tags corretamente
  const tags = Object.entries(filtros).reduce((acc, [key, value]) => {
    if (value) {
      const baseKey = key.replace(/Min|Max/, "");

      if (tagsNameValue[baseKey]) {
        const min = filtros[`${baseKey}Min`];
        const max = filtros[`${baseKey}Max`];

        if (min || max) {
          if (!acc.some((tag) => tag.key === baseKey)) {
            acc.push({
              key: baseKey,
              label: `${tagsNameValue[baseKey]}: ${min || "0"} até ${
                max || "∞"
              }`,
            });
          }
          return acc;
        }
      }

      if (!key.includes("Min") && !key.includes("Max")) {
        acc.push({ key, label: value });
      }
    }
    return acc;
  }, []);

  // Função para remover um filtro específico
  const removerFiltro = (key) => {
    const newParams = new URLSearchParams(searchParams);

    // Se for um filtro de faixa (Min/Max), remove os dois
    if (tagsNameValue[key]) {
      newParams.delete(`${key}Min`);
      newParams.delete(`${key}Max`);
    } else {
      newParams.delete(key);
    }

    setSearchParams(newParams);
  };

  return (
    <Container>
      <Dialog.Root>
        <div className="filter-footer">
          <div className="filtro-armazens">
            <h4>Filtrar por Armazéns:</h4>
            {armazensDisponiveis.map((armazem) => (
              <label key={armazem}>
                <input
                  type="checkbox"
                  checked={armazensSelecionados.includes(armazem)}
                  onChange={() => toggleArmazem(armazem)}
                />
                {armazem}
              </label>
            ))}
          </div>

          <div className="buttons">
            <Dialog.Trigger asChild>
              <Button>
                <FilterIcon size={16} />
                Adicionar Filtros
              </Button>
            </Dialog.Trigger>
            <Button variant="ghost" onClick={limparFiltros}>
              <FilterX size={16} />
              Remover Filtros
            </Button>
          </div>
        </div>

        {isValue && (
          <div className="tags">
            <p>Filtros aplicados:</p>

            {tags.map(({ key, label }) => (
              <Tag key={key}>
                {label}
                <button onClick={() => removerFiltro(key)}>
                  <X size={16} />
                </button>
              </Tag>
            ))}
          </div>
        )}
        <Dialog.Portal>
          <StyledOverlay className="DialogOverlay" />
          <StyledContent className="DialogContent">
            <div className="filtros">
              <div className="dubble">
                <label>
                  Serviço
                  <input
                    type="text"
                    value={filtros.servico}
                    onChange={(e) => atualizarFiltro("servico", e.target.value)}
                  />
                </label>
                <label>
                  Documento
                  <input
                    type="text"
                    value={filtros.documento}
                    onChange={(e) =>
                      atualizarFiltro("documento", e.target.value)
                    }
                  />
                </label>
              </div>
              <div className="dubble">
                <label>
                  Embalagem
                  <input
                    type="text"
                    value={filtros.emb}
                    onChange={(e) => atualizarFiltro("emb", e.target.value)}
                  />
                </label>
                <label>
                  Operador
                  <input
                    type="text"
                    value={filtros.operador}
                    onChange={(e) =>
                      atualizarFiltro("operador", e.target.value)
                    }
                  />
                </label>
              </div>
              <label>
                Cliente
                <input
                  type="text"
                  value={filtros.cliente}
                  onChange={(e) => atualizarFiltro("cliente", e.target.value)}
                />
              </label>
              <label>
                Status
                <input
                  type="text"
                  value={filtros.status}
                  onChange={(e) => atualizarFiltro("status", e.target.value)}
                />
              </label>
              <div className="dubble">
                <label>
                  Conclusão
                  <input
                    type="text"
                    value={filtros.conclusao}
                    onChange={(e) =>
                      atualizarFiltro("conclusao", e.target.value)
                    }
                  />
                </label>
                <label>
                  Sacas
                  <div className="input-dubble">
                    <input
                      type="number"
                      placeholder="Min"
                      min="0"
                      value={filtros.sacasMin}
                      onChange={(e) =>
                        atualizarFiltro("sacasMin", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      placeholder="Max"
                      min="0"
                      value={filtros.sacasMax}
                      onChange={(e) =>
                        atualizarFiltro("sacasMax", e.target.value)
                      }
                    />
                  </div>
                </label>
              </div>
              <div className="dubble">
                <label>
                  Remoção
                  <div className="input-dubble">
                    <input
                      type="number"
                      placeholder="Min"
                      min="0"
                      value={filtros.remocMin}
                      onChange={(e) =>
                        atualizarFiltro("remocMin", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      placeholder="Max"
                      min="0"
                      value={filtros.remocMax}
                      onChange={(e) =>
                        atualizarFiltro("remocMax", e.target.value)
                      }
                    />
                  </div>
                </label>
                <label>
                  Total de Bags
                  <div className="input-dubble">
                    <input
                      type="number"
                      placeholder="Min"
                      min="0"
                      value={filtros.totalBgMin}
                      onChange={(e) =>
                        atualizarFiltro("totalBgMin", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      placeholder="Max"
                      min="0"
                      value={filtros.totalBgMax}
                      onChange={(e) =>
                        atualizarFiltro("totalBgMax", e.target.value)
                      }
                    />
                  </div>
                </label>
              </div>
              <label>
                Total de Bags Pendentes
                <div className="input-dubble">
                  <input
                    className="input-number"
                    type="number"
                    placeholder="Min"
                    min="0"
                    value={filtros.totalBgPenMin}
                    onChange={(e) =>
                      atualizarFiltro("totalBgPenMin", e.target.value)
                    }
                  />
                  <input
                    className="input-number"
                    type="number"
                    placeholder="Max"
                    min="0"
                    value={filtros.totalBgPenMax}
                    onChange={(e) =>
                      atualizarFiltro("totalBgPenMax", e.target.value)
                    }
                  />
                </div>
              </label>
            </div>
            <CloseButton>
              <X size={24} color="#01121F" />
            </CloseButton>
          </StyledContent>
        </Dialog.Portal>
      </Dialog.Root>
    </Container>
  );
}
