import { Container } from "./style";

export function Card({
  title,
  bgColor,
  valueSacas,
  valueDocs,
  valuePercentSacas,
  valuePercentDocs,
  children,
}) {
  return (
    <Container bgColor={bgColor}>
      <p>{title}</p>
      {children}
      <div>
        <p className="valueKg">{valueDocs}</p>
        <p className="saldo">Documentos</p>
      </div>

      <div>
        <p className="value">
          {Math.round(valueSacas).toLocaleString("pt-BR")}
        </p>
        <p className="saldo">sacas</p>
      </div>

      <div>
        <div>
          <p className="valueKg">{valuePercentSacas}</p>
          <p className="saldo">% sacas</p>
        </div>
        <div>
          <p className="valueKg">{valuePercentDocs}</p>
          <p className="saldo">% docs.</p>
        </div>
      </div>
    </Container>
  );
}
