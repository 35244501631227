import { useMemo } from "react";
import ChartTotal from "../../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";

export function ChartMonth({ widthSize, data }) {
  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const grouped = {};

    data.forEach((item) => {
      const [day, month, year] = item.DAT_SAIDA.split("/").map(Number);
      const monthKey = `${month.toString().padStart(2, "0")}/${year}`;

      if (!grouped[monthKey]) {
        grouped[monthKey] = { fisica: 0, juridica: 0 };
      }

      if (item.FLG_FJ === "F") {
        grouped[monthKey].fisica += item.NUM_SACAS_CLIENTE || 0;
      } else if (item.FLG_FJ === "J") {
        grouped[monthKey].juridica += item.NUM_SACAS_CLIENTE || 0;
      }
    });

    const labels = Object.keys(grouped).sort((a, b) => {
      const [monthA, yearA] = a.split("/").map(Number);
      const [monthB, yearB] = b.split("/").map(Number);
      return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
    });

    const fisicaData = labels.map((key) => grouped[key].fisica);
    const juridicaData = labels.map((key) => grouped[key].juridica);

    const datasets = [
      {
        type: "bar",
        label: "Física",
        backgroundColor: "#047857",
        data: fisicaData,
      },
      {
        type: "bar",
        label: "Jurídica",
        backgroundColor: "#003d73",
        data: juridicaData,
      },
    ];

    return { datasets, labels };
  }, [data]);

  return (
    <ChartTotal
      datasetGraphic={dataSets}
      height="185"
      width="640"
      titleTooltip="Mensal"
      displayDataLabel={true}
      responsive={widthSize}
    />
  );
}
