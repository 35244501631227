import { Route } from "react-router-dom";
import RelatoriosArm from "../../Modules/Armazem/Relatorios/RelatoriosArm";
import { BoletimMovimentacao } from "../../Modules/Armazem/Relatorios/BoletimMovimentacao/BoletimMovimentacao";
import { SelectedProvider } from "../../Modules/Armazem/Relatorios/BoletimMovimentacao/Context/SelectedProdutor/SelectedProvider";

export function RelatoriosArmRoute() {
  return (
    <>
      <Route
        element={<RelatoriosArm />}
        path="armazem/relatorios/extrato-cafe"
        exact
      />
      <Route
        element={<RelatoriosArm />}
        path="armazem/relatorios/estoque-fisico"
        exact
      />
      <Route
        element={
          <SelectedProvider>
            {" "}
            {/* 🔹 Apenas essa rota terá o contexto */}
            <BoletimMovimentacao />
          </SelectedProvider>
        }
        path="armazem/relatorios/boletim"
        exact
      />
    </>
  );
}
