import { TextSearch, X } from "lucide-react";
import { HeaderSection, Table } from "../../style";
import { TableHead } from "../TableHead";
import { Dialog } from "radix-ui";
import {
  ButtonDetails,
  CloseButton,
  Container,
  StyledContent,
  StyledDescription,
  StyledOverlay,
  StyledTitle,
} from "./style";
import { ModalContent } from "./ModalContent";

export function Armazem({ armazem, filtros }) {
  const sacasMin = filtros.sacasMin ? parseInt(filtros.sacasMin, 10) : 0;
  const sacasMax = filtros.sacasMax ? parseInt(filtros.sacasMax, 10) : Infinity;
  const remocMin = filtros.remocMin ? parseInt(filtros.remocMin, 10) : 0;
  const remocMax = filtros.remocMax ? parseInt(filtros.remocMax, 10) : Infinity;
  const totalBgMin = filtros.totalBgMin ? parseInt(filtros.totalBgMin, 10) : 0;
  const totalBgMax = filtros.totalBgMax
    ? parseInt(filtros.totalBgMax, 10)
    : Infinity;
  const totalBgPenMin = filtros.totalBgPenMin
    ? parseInt(filtros.totalBgPenMin, 10)
    : 0;
  const totalBgPenMax = filtros.totalBgPenMax
    ? parseInt(filtros.totalBgPenMax, 10)
    : Infinity;

  const dadosFiltrados = armazem.dataArmazem.filter((item) => {
    return (
      (filtros.servico === "" ||
        (item.S_SERVICO && item.S_SERVICO.includes(filtros.servico))) &&
      (filtros.documento === "" ||
        (item.N_DOCUMENTO &&
          String(item.N_DOCUMENTO).includes(filtros.documento))) &&
      (filtros.emb === "" ||
        (item.S_EMBALAGEM && item.S_EMBALAGEM.includes(filtros.emb))) &&
      (filtros.operador === "" ||
        (item.S_OPERADOR && item.S_OPERADOR.includes(filtros.operador))) &&
      (filtros.cliente === "" ||
        (item.S_NOM_CADASTRO &&
          item.S_NOM_CADASTRO.includes(filtros.cliente))) &&
      (filtros.status === "" ||
        (item.S_STATUS && item.S_STATUS.includes(filtros.status))) &&
      (filtros.conclusao === "" ||
        item.S_CONCLUSAO.includes(filtros.conclusao)) &&
      item.N_BAGS_ARM >= totalBgMin &&
      item.N_BAGS_ARM <= totalBgMax &&
      item.N_BAGS_PEND_ARM >= totalBgPenMin &&
      item.N_BAGS_PEND_ARM <= totalBgPenMax &&
      item.N_SACAS_ARM >= sacasMin &&
      item.N_SACAS_ARM <= sacasMax &&
      item.N_REMOCAO_ARM >= remocMin &&
      item.N_REMOCAO_ARM <= remocMax
    );
  });

  const totalSacas = dadosFiltrados.reduce((acc, item) => {
    return (acc += item.N_SACAS_ARM);
  }, 0);

  return (
    <Container>
      <HeaderSection>
        <h2>
          Armazém {armazem.armazem} - Total Sacas{" "}
          {totalSacas.toLocaleString("pt-br")}
        </h2>
      </HeaderSection>
      <Table>
        <TableHead />
        <tbody>
          {dadosFiltrados.length > 0 ? (
            dadosFiltrados.map((item) => (
              <tr key={item.id}>
                <td>{item.S_SERVICO}</td>
                <td>{item.N_DOCUMENTO}</td>
                <td>{item.S_NOM_CADASTRO}</td>
                <td>{item.N_SACAS_ARM.toLocaleString("pt-BR")}</td>
                <td>{item.N_REMOCAO_ARM}</td>
                <td>{item.N_BAGS_ARM}</td>
                <td>{item.N_BAGS_PEND_ARM}</td>
                <td title={item.S_EMBALAGEM}>
                  {item.S_EMBALAGEM ? item.S_EMBALAGEM.split(";")[0] : ""}
                  {item.S_EMBALAGEM &&
                    item.S_EMBALAGEM.split(";").length > 1 &&
                    " (...)"}
                </td>
                <td title={item.S_OPERADOR}>
                  {item.S_OPERADOR ? item.S_OPERADOR.split(";")[0] : ""}
                  {item.S_OPERADOR &&
                    item.S_OPERADOR.split(";").length > 1 &&
                    " (...)"}
                </td>
                <td>{item.S_STATUS}</td>
                <td>{item.S_CONCLUSAO}</td>
                <td>
                  <Dialog.Root>
                    <Dialog.Trigger asChild>
                      <ButtonDetails
                        title={`Detalhes do documento ${item.N_DOCUMENTO}`}
                        type="button"
                      >
                        <TextSearch size={16} color="#fff" />
                      </ButtonDetails>
                    </Dialog.Trigger>
                    <Dialog.Portal>
                      <StyledOverlay className="DialogOverlay" />
                      <StyledContent className="DialogContent">
                        <StyledTitle className="DialogTitle">
                          Documento {item.N_DOCUMENTO}
                        </StyledTitle>
                        <StyledDescription className="DialogDescription">
                          Detalhes geral do documento.
                        </StyledDescription>
                        <ModalContent documento={item} />
                        <CloseButton>
                          <X size={24} color="#01121F" />
                        </CloseButton>
                      </StyledContent>
                    </Dialog.Portal>
                  </Dialog.Root>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10">Nenhum dado encontrado</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}
