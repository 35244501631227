import { apiDecryptURL } from "../utils/webService";

export const getProdutores = async (
  dono,
  user,
  password,
  codEmpresa,
  cpfOuCnpj
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuProdutorRetJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${codEmpresa}&Cnpj=${cpfOuCnpj}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getDadosProdutores = async (dono, user, password) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/RetNomeCPFCadastroJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getExtratoProdutores = async (
  dono,
  user,
  password,
  codEmpresa,
  cpfOuCnpj,
  date = ""
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuExtratoCafeRetJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${codEmpresa}&Cnpj=${cpfOuCnpj}&DataExtrato=${date}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getExtratoFisico = async (
  dono,
  user,
  password,
  codEmpresa,
  cpfOuCnpj
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuFisicoCafeRetJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&Empresa=${codEmpresa}&Cnpj=${cpfOuCnpj}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getAvisos = async (dono, user, password, cnpj) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuNoticiasRetJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&CpfUsuario=${cnpj}&CnpjGrupo=${cnpj}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const createUpdateDeleteAvisos = async (
  dono,
  user,
  password,
  operacao,
  cnpj,
  id,
  dataIni,
  dataFim,
  aviso,
  ativo
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuCadastraNoticiasJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&Operacao=${operacao}&CnpjGrupo=${cnpj}&Id=${id}&DataHoraIni=${dataIni}&DataHoraFim=${dataFim}&Noticia=${aviso}&Ativo=${ativo}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getProdutoresVinculados = async (
  dono,
  user,
  password,
  cpfUser,
  cnpjGroup
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuPermUsuarioRetJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&CpfUsuario=${cpfUser}&CnpjGrupo=${cnpjGroup}&Token=0&StatusToken=L`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const createUpdateDeleteUsersApp = async (
  dono,
  user,
  password,
  operacao,
  id,
  cpfOuCnpj,
  username,
  email,
  telefone,
  observacao,
  status
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuCadastraUsuarioJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&Operacao=${operacao}&Id=${id}&CnpjUsuario=${cpfOuCnpj}&NomeUsuario=${username}&EmailUsuario=${email}&TelefoneUsuario=${telefone}&Observacoes=${observacao}&Ativo=${status}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const createUpdateDeleteVincular = async (
  dono,
  user,
  password,
  operacao,
  cnpjGroup,
  idVinculacao,
  idGroup,
  idUser,
  flgCadastro,
  codProdutor,
  codFazenda,
  status
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuCadastraPermUsuarioJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&Operacao=${operacao}&CnpjGrupo=${cnpjGroup}&Id=${idVinculacao}&IdGrupo=${idGroup}&IdUsuario=${idUser}&FlgCadastro=${flgCadastro}&CodCadastro=${codProdutor}&CodFazenda=${codFazenda}&Ativo=${status}`;
  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const getDataSqlNotification = async (user, password, codSql) => {
  const api = apiDecryptURL();
  const url = "/WebService.asmx/GetSqlJSon";
  const auth = `Usuario=${user}&Senha=${password}&ComandoSQL=${codSql}`;
  const { data } = await api.post(url, auth);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};

export const updateNotifications = async (
  dono,
  user,
  password,
  caminhaoChega,
  abrePeso,
  fechaPeso,
  caminhaoSaida,
  classificaLote,
  abreIsd,
  fechaIsd,
  faturaServico,
  emiteNfe,
  emiteCtr,
  emiteOc,
  emiteRd,
  emiteTra,
  tempoEnvio = 15
) => {
  const api = apiDecryptURL();
  const url = "/WsPortalCafe.asmx/FuGravaConfigNotifyRetJSon";
  const authArmazem = `Dono=${dono}&Usuario=${user}&Senha=${password}&caminhao_chega=${caminhaoChega}&abre_peso=${abrePeso}&fecha_peso=${fechaPeso}&caminhao_saida=${caminhaoSaida}&classifica_lote=${classificaLote}&abre_isd=${abreIsd}&fecha_isd=${fechaIsd}&fatura_servico=${faturaServico}&emite_nfe=${emiteNfe}&emite_ctr=${emiteCtr}&emite_oc=${emiteOc}&emite_rd=${emiteRd}&emite_tra=${emiteTra}&tempo_envio=${tempoEnvio}`;

  const { data } = await api.post(url, authArmazem);

  const firstKey = Object.keys(data);
  const { [firstKey]: tableValue } = data;

  return tableValue;
};
