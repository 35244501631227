import { useState, useEffect, useContext, useMemo } from "react";
import moment from "moment";
import { Container, Empty, HeaderSection, Table, TableBottom } from "./style";
import { TableHead } from "./TableHead";
import { getBoletimISD } from "../../../../../../../services/api";
import { AuthContext } from "../../../../../../../contexts/Auth/AuthContext";
import { SelectedContext } from "../../../Context/SelectedProdutor/SelectedContext";
import Loading from "../../../../../../../components/Layouts/Loading/Loading";

export function TableGuias() {
  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext);
  const { endDate, produtorSelected } = useContext(SelectedContext);
  const [dataGuias, setDataGuias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { totaisEntrada, totaisSaida, totaisQuebra } = useMemo(() => {
    return dataGuias.reduce(
      (acc, registro) => {
        const sacasEntrada = registro.SACAS_ENTRADA || 0;
        const pesoEntrada = registro.PESO_ENTRADA || 0;

        const sacasSaida = registro.SACAS_SAIDA || 0;
        const pesoSaida = registro.PESO_SAIDA || 0;

        const qtdPedra = registro.NUM_PEDRA || 0;
        const qtdPo = registro.NUM_PO || 0;
        const qtdAmostra = registro.NUM_AMOSTRA || 0;

        acc.totaisEntrada.sacas += sacasEntrada;
        acc.totaisEntrada.peso += pesoEntrada;
        acc.totaisSaida.sacas += sacasSaida;
        acc.totaisSaida.peso += pesoSaida;

        acc.totaisQuebra.pedra += qtdPedra;
        acc.totaisQuebra.po += qtdPo;
        acc.totaisQuebra.amostra += qtdAmostra;

        return acc;
      },
      {
        totaisEntrada: { sacas: 0, peso: 0 }, // 🔹 Inicialização correta
        totaisSaida: { sacas: 0, peso: 0 }, // 🔹 Inicialização correta
        totaisQuebra: { pedra: 0, po: 0, amostra: 0 }, // 🔹 Inicialização correta
      }
    );
  }, [dataGuias]);

  useEffect(() => {
    async function fetchDataGuias() {
      setLoading(true);
      try {
        const res = await getBoletimISD(
          dono,
          userAuth,
          password,
          codEmpresa,
          produtorSelected ? produtorSelected.cpf : "",
          endDate
        );

        // Ordenar os dados pelo campo NUM_IS em ordem crescente
        const dadosOrdenados = res.sort((a, b) => a.NUM_IS - b.NUM_IS);

        setDataGuias(dadosOrdenados);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchDataGuias();
  }, [dono, userAuth, password, codEmpresa, produtorSelected, endDate]);

  return (
    <Container>
      {loading && <Loading />}
      {error && <div>Erro ao carregar guias.</div>}

      {!loading && dataGuias.length === 0 && <Empty>NENHUMA GUIA ABERTA</Empty>}
      {!loading && dataGuias.length > 0 && (
        <>
          <HeaderSection>
            <h2>GUIAS ABERTAS</h2>
          </HeaderSection>
          <Table>
            <TableHead />
            <tbody>
              {dataGuias.map((item) => (
                <tr key={item.id}>
                  <td>{item.NUM_IS}</td>
                  <td>{moment(item.DAT_ABERTURA).format("DD/MM/YYYY")}</td>
                  <td>
                    {item.DAT_FECHAMENTO
                      ? moment(item.DAT_FECHAMENTO).format("DD/MM/YYYY")
                      : ""}
                  </td>
                  <td>{item.NOM_TIPO_IS}</td>
                  <td>{item.SACAS_ENTRADA.toLocaleString("pt-BR")}</td>
                  <td>{item.SACAS_SAIDA.toLocaleString("pt-BR")}</td>
                  <td>
                    {item.PESO_ENTRADA.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td>
                    {item.PESO_SAIDA.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td>{item.NUM_PEDRA.toLocaleString("pt-BR")}</td>
                  <td>{item.NUM_PO.toLocaleString("pt-BR")}</td>
                  <td>{item.NUM_PO_ATM.toLocaleString("pt-BR")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <TableBottom>
            <div className="atual">
              <h2>TOTAIS DE DESPEJOS E RESULTADOS</h2>
            </div>
            <div>
              <p>
                Despejo: {totaisEntrada.sacas.toLocaleString("pt-BR")} sacas /{" "}
                {totaisEntrada.peso.toLocaleString("pt-BR")} kgs
              </p>
              <p>
                Resultado: {totaisSaida.sacas.toLocaleString("pt-BR")} sacas /{" "}
                {totaisSaida.peso.toLocaleString("pt-BR")} kgs
              </p>
            </div>
            <div className="atual">
              <h2>TOTAIS DE QUEBRAS</h2>
            </div>
            <div>
              <p>
                Pedra: {totaisQuebra.pedra.toLocaleString("pt-BR")} kgs / Pó:{" "}
                {totaisQuebra.po.toLocaleString("pt-BR")} kgs / Amostra:{" "}
                {totaisQuebra.amostra.toLocaleString("pt-BR")} kgs{" "}
              </p>
            </div>
          </TableBottom>
        </>
      )}
    </Container>
  );
}
