import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export function ChartSacasServicoRosca({ dataApi, armazem }) {
  // Obter serviços únicos
  const servicosDisponiveis = [
    ...new Set(dataApi.map((item) => item.S_SERVICO)),
  ];

  // Somar sacas por serviço
  const totalSacasServico = servicosDisponiveis.map((servico) =>
    dataApi
      .filter((item) => item.S_SERVICO === servico)
      .reduce((acc, curr) => acc + curr.N_SACAS_ARM, 0)
  );

  // Calcular o total geral de sacas
  const totalGeral = totalSacasServico.reduce((acc, val) => acc + val, 0);

  const backgroundColors = [
    "#4bc072",
    "#8f5ef3",
    "#e9a909",
    "#1581c9",
    "#ee476b",
    "#d3521f",
  ];

  const data = {
    labels: servicosDisponiveis,
    datasets: [
      {
        data: totalSacasServico,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        borderWidth: 0,
        radius: "90%",
        cutout: "60%",
        hoverOffset: "20",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Armazém ${armazem}`,
        color: "#fff",
      },
      legend: {
        position: "bottom",
        labels: {
          color: "#FFF",
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            const percentage = ((value / totalGeral) * 100).toFixed(1);
            return `${tooltipItem.label}: ${value} sacas (${percentage}%)`;
          },
        },
      },
      datalabels: {
        color: "#FFF",
        font: {
          weight: "bold",
        },
        formatter: (value) => {
          const percentage = ((value / totalGeral) * 100).toFixed(1);
          return `${value} (${percentage}%)`;
        },
      },
    },
  };

  return (
    <div style={{ height: "300px", width: "300px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
}
