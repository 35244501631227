import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;

  .wrapper {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
  }

  .TabsRoot {
    display: flex;
    flex-direction: column;
  }

  .TabsList {
    display: inline-flex;
    gap: 1rem;
    padding: 0;
    border-bottom: 1px solid rgb(202, 202, 202);
  }

  .TabsTrigger {
    position: relative;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
  }

  .TabsTrigger:hover:not([data-state="active"]) {
    opacity: 0.8;
  }

  .TabsTrigger[data-state="active"] {
    opacity: 1;
    color: var(--blue-a18);
  }

  .TabsTrigger::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -1px;
    width: 0%;
    height: 2px;
    background-color: var(--blue-a18);
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  .TabsTrigger[data-state="active"]::after {
    width: 100%;
  }
`;

export const HeaderSection = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue-a18);
  border-bottom: none;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: var(--blue-a1);
  background-color: var(--blue-a18);

  h2 {
    font-weight: 400;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #595959;
    text-align: center;
    padding: 2px;
  }

  th {
    background-color: var(--gray-c2);
  }

  td {
    text-transform: uppercase;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
`;
