import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  & > div:first-child {
    flex: 1;
  }
`;

export const DateDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DateInput = styled.input`
  padding: 8px 5px;
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--blue-a1);
  cursor: pointer;

  &::placeholder {
    color: #aaa;
  }

  &:read-only {
    cursor: default;
  }
`;

export const Icons = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  max-width: 400px;
`;
