import { useMemo } from "react";
import ChartTotal from "../../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";

export function ChartYear({ widthSize, data }) {
  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const grouped = {};

    data.forEach((item) => {
      const [day, month, year] = item.DAT_ENTRADA.split("/").map(Number);
      const yearKey = `${year}`;

      if (!grouped[yearKey]) {
        grouped[yearKey] = { fisica: 0, juridica: 0 };
      }

      if (item.FLG_FJ === "F") {
        grouped[yearKey].fisica += item.NUM_SACAS_CLIENTE || 0;
      } else if (item.FLG_FJ === "J") {
        grouped[yearKey].juridica += item.NUM_SACAS_CLIENTE || 0;
      }
    });

    const labels = Object.keys(grouped).sort((a, b) => Number(a) - Number(b));

    const fisicaData = labels.map((key) => grouped[key].fisica);
    const juridicaData = labels.map((key) => grouped[key].juridica);

    const datasets = [
      {
        type: "bar",
        label: "Física",
        backgroundColor: "#047857",
        data: fisicaData,
      },
      {
        type: "bar",
        label: "Jurídica",
        backgroundColor: "#003d73",
        data: juridicaData,
      },
    ];

    return { datasets, labels };
  }, [data]);

  return (
    <ChartTotal
      datasetGraphic={dataSets}
      height="185"
      width="640"
      titleTooltip="Anual"
      displayDataLabel={true}
      responsive={widthSize}
    />
  );
}
