import styled from "styled-components";

export const Container = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .charts {
    background-color: var(--blue-a18);
    color: var(--blue-a1);
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .charts-faixa {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 300px;
    gap: 0.25rem;

    p {
      color: var(--blue-a1);
      margin-bottom: 0.25rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .chart-client {
      flex: 1;
      width: 100%;
      height: 300px;
      border: 2px solid var(--blue-a16);
      border-radius: 8px;
    }

    .chart-sacas {
      flex: 1;
      width: 100%;
      border: 2px solid var(--blue-a16);
      border-radius: 8px;
    }
  }

  .charts-rosca {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center; /* Centraliza quando há menos de três */
    align-items: center;
  }

  .charts-rosca > div {
    flex: 1 1 300px; /* Define um tamanho flexível */
    max-width: 300px; /* Limita a largura dos gráficos */
  }
`;
