import { useState, useEffect, useContext } from "react";
import moment from "moment";
import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";
import { getDadosProdutores } from "../../../../../../services/apiPortalProdutor";
import { Container, DateDisplay, DateInput, Icons } from "./style";
import { Calendar, X } from "lucide-react";
import SelectFilter from "../../../../../../components/Layouts/SelectFilter/SelectFilter";
import DatePickerModal from "./DatePickerModal";
import { SelectedContext } from "../../Context/SelectedProdutor/SelectedContext";

export function SelectAndDate() {
  const { dono, userAuth, password } = useContext(AuthContext);
  const {
    setProdutores,
    setDate,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    produtores,
    handleSelectProdutor,
  } = useContext(SelectedContext);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen((prev) => !prev);

  // Função para adicionar a máscara de data no formato DD/MM/YYYY
  const applyDateMask = (value) => {
    // Remove tudo que não for número
    value = value.replace(/[^0-9]/g, "");

    // Adiciona a máscara conforme os números são digitados
    if (value.length <= 2) {
      return value; // Até o dia (DD)
    } else if (value.length <= 4) {
      return `${value.slice(0, 2)}/${value.slice(2)}`; // Até o mês (DD/MM)
    } else if (value.length <= 8) {
      return `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`; // Até o ano (DD/MM/YYYY)
    }
    return value; // Limita a 10 caracteres
  };

  // Atualiza a data quando o usuário digita manualmente
  const handleDateInput = (event, type) => {
    let value = event.target.value;

    // Aplica a máscara na data
    value = applyDateMask(value);

    if (type === "start") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }

    // Só atualiza o setDate do pai se a data for válida
    const isValid = moment(value, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      setDate((prev) => ({
        ...prev,
        [type === "start" ? "firstDate" : "lastDate"]: value,
      }));
    }
  };

  // Atualiza a data ao selecionar no DatePicker
  const handleChange = (dates) => {
    const [start, end] = dates;
    const formattedStart = start ? moment(start).format("DD/MM/YYYY") : "";
    const formattedEnd = end ? moment(end).format("DD/MM/YYYY") : "";

    setStartDate(formattedStart);
    setEndDate(formattedEnd);

    setDate({
      firstDate: formattedStart,
      lastDate: formattedEnd,
    });
    if (end) {
      setModalOpen(false);
    }
  };

  // Limpa as datas
  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setDate({ firstDate: "", lastDate: "" });
  };

  useEffect(() => {
    async function fetch() {
      const res = await getDadosProdutores(dono, userAuth, password);
      const produtoresData = res.map((item) => ({
        value: `${item.COD_CLIENTE}-${item.NOM_CLIENTE} (${item.NUM_CPFCGC})`,
        label: `${item.COD_CLIENTE}-${item.NOM_CLIENTE} (${item.NUM_CPFCGC})`,
        cpf: item.NUM_CPFCGC,
        nome: item.NOM_CLIENTE,
      }));
      setProdutores(produtoresData);
    }
    fetch();
  }, [dono, userAuth, password, setProdutores]);

  return (
    <Container>
      <SelectFilter options={produtores} onChange={handleSelectProdutor} />

      <DateDisplay>
        <DateInput
          type="text"
          value={startDate}
          placeholder="Início"
          onChange={(e) => handleDateInput(e, "start")}
        />
        <DateInput
          type="text"
          value={endDate}
          placeholder="Fim"
          onChange={(e) => handleDateInput(e, "end")}
        />

        <Icons>
          <Calendar size={20} onClick={toggleModal} />
          <X size={20} onClick={handleClear} />
        </Icons>
      </DateDisplay>

      <DatePickerModal
        isOpen={modalOpen}
        onClose={toggleModal}
        onChange={handleChange}
        startDate={startDate ? moment(startDate, "DD/MM/YYYY").toDate() : ""}
        endDate={endDate ? moment(endDate, "DD/MM/YYYY").toDate() : ""}
      />
    </Container>
  );
}
