import { useContext } from "react";

import { AuthContext } from "../../../../contexts/Auth/AuthContext";

import { useQuery } from "@tanstack/react-query";

import { getProgramacaoArmazens } from "../../../../services/api";

export function useServicoArmazem() {
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  const { data, isLoading, error } = useQuery({
    queryKey: ["servico-armazem"],
    queryFn: async () => {
      const data = await getProgramacaoArmazens(
        dono,
        userAuth,
        password,
        codEmpresa,
        codFilial
      );
      return data;
    },
    refetchInterval: 180000, // 3 minuto
  });

  return {
    isLoading,
    data,
    error,
  };
}
