import { Container } from "./style";

import { ChartSacasArmazem } from "./chartSacasArmazem";
import { ChartSacasServico } from "./chartSacasServico";
import { ChartSacasServicoRosca } from "./chartRosca";

export function Charts({
  armazensDisponiveis,
  armazensAgrupados,
  armazensData,
}) {
  const sacasTotal = armazensData.reduce((acc, item) => {
    acc += item.N_SACAS_ARM;
    return acc;
  }, 0);

  const arrayOrdenado = armazensAgrupados.sort((a, b) =>
    a.armazem.localeCompare(b.armazem)
  );

  return (
    <Container>
      <div className="charts">
        <h3>Total de Sacas - {sacasTotal.toLocaleString("pt-br")}</h3>
        <div className="charts-faixa">
          <div className="chart-client">
            <ChartSacasArmazem
              dataApi={armazensData}
              armazensDisponiveis={armazensDisponiveis}
            />
          </div>
          <div className="chart-sacas">
            <ChartSacasServico dataApi={armazensData} />
          </div>
        </div>
      </div>
      <div className="charts">
        <h3>Total de sacas por armazém e serviço</h3>
        <div className="charts-rosca">
          {arrayOrdenado.map((item) => (
            <ChartSacasServicoRosca
              dataApi={item.dataArmazem}
              armazem={item.armazem}
            />
          ))}
        </div>
      </div>
    </Container>
  );
}
