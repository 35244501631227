import { useState, useContext, useEffect } from "react";
import { Tabs } from "radix-ui";
import {
  calcularSaldoSacasTotal,
  calcularSaldoPesoTotal,
} from "../../../../../../utils/functions";
import { Card } from "../Card";
import { TableFazenda } from "../Tables/TableFazenda";
import { Cards, Container } from "./style";
import {
  ArrowUpToLine,
  ArrowDownToLine,
  ArrowLeftRight,
  ArrowLeftToLine,
  ArrowRightToLine,
  History,
  Split,
  TrendingDown,
  TrendingUp,
  ChartArea,
  ClipboardList,
  Factory,
  FileCog,
  DiamondPlus,
  DiamondMinus,
} from "lucide-react";
import { TableLotes } from "../Tables/TableLotes";
import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";
import { SelectedContext } from "../../Context/SelectedProdutor/SelectedContext";
import { getBoletimISD, getBoletimLotes } from "../../../../../../services/api";
import Loading from "../../../../../../components/Layouts/Loading/Loading";
import { TableGuias } from "../Tables/TableGuias";
import { TableLotesGuias } from "../Tables/TableLoteGuias";

export function DataBoletim({ data }) {
  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext);
  const { endDate, produtorSelected } = useContext(SelectedContext);
  const [dataLotes, setDataLotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  function agrupado(content) {
    const res = content.reduce((acc, item) => {
      const { COD_FAZENDA, NOM_FAZE, NUM_PR } = item;
      const chave = `${COD_FAZENDA}-${NUM_PR || "SEM_NUM_PR"}`; // Cria uma chave única para cada combinação

      if (!acc[chave]) {
        acc[chave] = {
          COD_FAZENDA,
          NOM_FAZE,
          NUM_PR: NUM_PR || "SEM_NUM_PR", // Se for null, define como "SEM_NUM_PR"
          registros: [],
        };
      }

      acc[chave].registros.push(item);

      return acc;
    }, {});
    return res;
  }

  // Convertendo o objeto agrupado de volta para um array
  const fazendas = Object.values(agrupado(data));
  const lotes = Object.values(agrupado(dataLotes));

  const saldoSacasAnterior = calcularSaldoSacasTotal(data, "A");
  const saldoSacasEntradas = calcularSaldoSacasTotal(data, "E");
  const saldoSacasResultados = calcularSaldoSacasTotal(data, "R");
  const saldoSacasEmbarques = calcularSaldoSacasTotal(data, "S");
  const saldoSacasTransf = calcularSaldoSacasTotal(data, "T");
  const saldoSacasDespejos = calcularSaldoSacasTotal(data, "I");
  const saldoSacasQuebAcre = calcularSaldoSacasTotal(data, "Q");
  // const saldoSacasPicote = calcularSaldoPesoTotal(data, "P") / 60;
  const saldoSacasAtual = calcularSaldoSacasTotal(data, "X");

  const saldoPesoAnterior = calcularSaldoPesoTotal(data, "A");
  const saldoPesoEntradas = calcularSaldoPesoTotal(data, "E");
  const saldoPesoResultados = calcularSaldoPesoTotal(data, "R");
  const saldoPesoEmbarques = calcularSaldoPesoTotal(data, "S");
  const saldoPesoTransf = calcularSaldoPesoTotal(data, "T");
  const saldoPesoDespejos = calcularSaldoPesoTotal(data, "I");
  const saldoPesoQuebAcre = calcularSaldoPesoTotal(data, "Q");
  const saldoPesoPicote = calcularSaldoPesoTotal(data, "P");
  const saldoPesoAtual = calcularSaldoPesoTotal(data, "X");

  let SacasAcrescimo = 0;
  let PesoAcrescimo = 0;
  let SacasQuebra = 0;
  let PesoQuebra = 0;

  function definirQuebraAcrescimo(saldoSacasQuebAcre, saldoPesoQuebAcre) {
    if (saldoPesoQuebAcre < 0) {
      SacasAcrescimo = Math.abs(saldoSacasQuebAcre);
      PesoAcrescimo = Math.abs(saldoPesoQuebAcre);
      SacasQuebra = 0;
      PesoQuebra = 0;
    } else if (saldoPesoQuebAcre > 0) {
      SacasAcrescimo = 0;
      PesoAcrescimo = 0;
      SacasQuebra = saldoSacasQuebAcre;
      PesoQuebra = saldoPesoQuebAcre;
    } else {
      SacasAcrescimo = 0;
      PesoAcrescimo = 0;
      SacasQuebra = 0;
      PesoQuebra = 0;
    }
  }

  // Exemplo de uso
  definirQuebraAcrescimo(saldoSacasQuebAcre, saldoPesoQuebAcre);

  useEffect(() => {
    // Função para buscar os dados do boletim
    async function fetchDataLotes() {
      setLoading(true);
      try {
        const res = await getBoletimLotes(
          dono,
          userAuth,
          password,
          codEmpresa,
          produtorSelected ? produtorSelected.cpf : "",
          endDate
        );
        setDataLotes(res);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchDataLotes();
  }, [dono, userAuth, password, codEmpresa, produtorSelected, endDate]);

  return (
    <Container>
      <Cards>
        <Card
          service="Anterior"
          value={saldoSacasAnterior}
          bgColor="#038ac4"
          valueKg={saldoPesoAnterior}
        >
          <span>
            <History size={24} color="#FFF" />
          </span>
        </Card>
        <Card
          service="Entradas"
          value={saldoSacasEntradas}
          valueKg={saldoPesoEntradas}
          bgColor=" #00751F"
        >
          <span>
            <ArrowRightToLine size={24} color="#FFF" />
          </span>
        </Card>
        <Card
          service="Embarques"
          value={saldoSacasEmbarques}
          valueKg={saldoPesoEmbarques}
          bgColor="#6B0009"
        >
          <span>
            <ArrowLeftToLine size={24} color="#FFF" />
          </span>
        </Card>
        <Card
          service="Transferências"
          value={saldoSacasTransf}
          valueKg={saldoPesoTransf}
          bgColor="#cf5503"
        >
          <span>
            <ArrowLeftRight size={24} color="#FFF" />
          </span>
        </Card>
        <Card
          service="Picote"
          value={saldoPesoPicote}
          valueKg={0}
          bgColor="#a11f2a"
        >
          <span>
            <Split size={24} color="#FFF" />
          </span>
        </Card>
        <Card
          service="Despejos"
          value={saldoSacasDespejos}
          valueKg={saldoPesoDespejos}
          bgColor="#91000c"
        >
          <span>
            <ArrowDownToLine size={24} color="#FFF" />
          </span>
        </Card>

        <Card
          service="Resultados"
          value={saldoSacasResultados}
          valueKg={saldoPesoResultados}
          bgColor=" #019b2a"
        >
          <span>
            <ArrowUpToLine size={24} color="#FFF" />
          </span>
        </Card>

        <Card
          service="Acréscimos"
          value={SacasAcrescimo}
          valueKg={PesoAcrescimo}
          bgColor="#00916d"
        >
          <span>
            <DiamondPlus size={24} color="#FFF" />
          </span>
        </Card>

        <Card
          service="Quebras"
          value={SacasQuebra}
          valueKg={PesoQuebra}
          bgColor="#944700b2"
        >
          <span>
            <DiamondMinus size={24} color="#FFF" />
          </span>
        </Card>

        <Card
          service="Atual"
          value={saldoSacasAtual}
          valueKg={saldoPesoAtual}
          bgColor="#030F14"
        >
          {saldoSacasAtual > saldoSacasAnterior && (
            <span>
              <TrendingUp size={26} color="green" />
            </span>
          )}
          {saldoSacasAtual < saldoSacasAnterior && (
            <span>
              <TrendingDown size={26} color="red" />
            </span>
          )}
        </Card>
      </Cards>

      <Tabs.Root className="TabsRoot" defaultValue="tab1">
        <Tabs.List
          className="TabsList"
          aria-label="Análise de serviços por armazém"
        >
          <Tabs.Trigger className="TabsTrigger" value="tab1">
            <ChartArea size={20} />
            Movimentações
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="tab2">
            <ClipboardList size={20} />
            Lotes em estoque
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="tab3">
            <Factory size={20} />
            Guias abertas
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="tab4">
            <FileCog size={20} />
            Lotes em guias
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="tab1">
          <div className="fazendas">
            {fazendas.map((fazenda) => (
              <TableFazenda data={fazenda} key={fazenda.COD_FAZENDA} />
            ))}
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab2">
          <div className="fazendas">
            {loading && <Loading />}
            {error && <div>Erro ao carregar lotes.</div>}
            {lotes.map((lote) => (
              <TableLotes data={lote} key={lote.COD_FAZENDA} />
            ))}
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab3">
          <div className="fazendas">
            <TableGuias />
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab4">
          <div className="fazendas">
            <TableLotesGuias />
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </Container>
  );
}
