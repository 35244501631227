import { useState, useContext, useRef } from "react";
import { AuthContext } from "../../../../contexts/Auth/AuthContext.jsx";
import { getBoletim } from "../../../../services/api.js";
import { Button, Container, Header, ButtonPDF } from "./style";
import { SelectAndDate } from "./components/SelectAndDate";
import { DataBoletim } from "./components/DataBoletim/index.jsx";
import Loading from "../../../../components/Layouts/Loading/Loading.jsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { SelectedContext } from "./Context/SelectedProdutor/SelectedContext.jsx";

export function BoletimMovimentacao() {
  const [data, setData] = useState([]);

  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext);
  const { startDate, endDate, produtorSelected } = useContext(SelectedContext);

  const [loading, setLoading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const boletimRef = useRef(null); // Referência para capturar o conteúdo da tela

  // Função para buscar os dados do boletim
  async function fetchDataBoletim() {
    setLoading(true);
    const res = await getBoletim(
      dono,
      userAuth,
      password,
      codEmpresa,
      produtorSelected ? produtorSelected.cpf : "",
      startDate,
      endDate
    );
    setLoading(false);
    setData(res);
  }

  // Função para gerar o PDF
  const gerarPDF = async () => {
    setLoadingPDF(true); // Ativa o loading do PDF

    const elemento = boletimRef.current;

    html2canvas(elemento, { scale: 2 })
      .then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");

        const marginX = 10;
        const marginY = 10;
        const pageWidth = 210 - 2 * marginX;
        const pageHeight = 297 - 2 * marginY;
        const imgWidth = pageWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const nomArquivo =
          produtorSelected.nome + "-" + startDate + "-" + endDate + ".pdf";

        let heightLeft = imgHeight;
        let currentHeight = 0;

        while (heightLeft > 0) {
          const sectionHeight = Math.min(pageHeight, heightLeft);

          const sectionCanvas = document.createElement("canvas");
          sectionCanvas.width = canvas.width;
          sectionCanvas.height = (sectionHeight * canvas.width) / imgWidth;

          const sectionCtx = sectionCanvas.getContext("2d");
          sectionCtx.drawImage(
            canvas,
            0,
            currentHeight,
            canvas.width,
            sectionCanvas.height,
            0,
            0,
            sectionCanvas.width,
            sectionCanvas.height
          );

          const sectionImgData = sectionCanvas.toDataURL("image/png");

          if (currentHeight > 0) pdf.addPage();
          pdf.addImage(
            sectionImgData,
            "PNG",
            marginX,
            marginY,
            imgWidth,
            sectionHeight
          );

          currentHeight += sectionCanvas.height;
          heightLeft -= sectionHeight;
        }

        pdf.save(nomArquivo);
        setLoadingPDF(false); // Desativa o loading após gerar o PDF
      })
      .catch(() => {
        setLoadingPDF(false); // Garante que desativa o loading em caso de erro
      });
  };

  return (
    <Container ref={boletimRef}>
      <h3>Boletim de movimentação diária</h3>
      <Header>
        <SelectAndDate />
        <Button
          onClick={fetchDataBoletim}
          disabled={!startDate || !endDate || !produtorSelected || loading}
        >
          Gerar Boletim
        </Button>
        <ButtonPDF
          onClick={gerarPDF}
          disabled={
            !startDate || !endDate || !produtorSelected || loading || loadingPDF
          }
        >
          {loadingPDF ? "Gerando PDF..." : "Gerar PDF"}
        </ButtonPDF>
      </Header>
      {data && data.length > 0 && !loading && <DataBoletim data={data} />}
      {loading && <Loading />}
    </Container>
  );
}
