import { Container } from "./style";

export function Card({ service, value, valueKg, bgColor, children }) {
  return (
    <Container bgColor={bgColor}>
      <p className="saldo">Saldo total</p>
      <p className="service">{service}</p>
      {children}
      <div>
        <p className="value">
          {service !== "Picote"
            ? value.toLocaleString("pt-BR")
            : value.toLocaleString("pt-BR", {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })}
        </p>
        <p className="saldo">{service === "Picote" ? "kg" : "sacas"}</p>
      </div>
      <div>
        <p className="valueKg">{valueKg.toLocaleString("pt-BR")}</p>
        <p className="saldo">{service === "Picote" ? "sacas" : "kg"}</p>
      </div>
    </Container>
  );
}
