import { useState } from "react";
import { NavLink } from "react-router-dom";
import analises from "../../../assets/Icons Sidebar/icon-analises.svg";
import { Icon, ItemMenu } from "../style";

export function AnaliseItemMenu({ setShowMenu, selected }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ItemMenu className="wrapper" isOpen={isOpen}>
      <div onClick={() => setIsOpen(!isOpen)}>
        <img src={analises} alt="" className="menu_img" />
        Análises
        <Icon color="#fff" isOpen={isOpen} />
      </div>
      {isOpen && (
        <div className="links">
          <NavLink
            to="armazem/analises/carga-descarga"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Carga e descarga
          </NavLink>
          <NavLink
            to="armazem/analises/entradas"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Entradas
          </NavLink>
          <NavLink
            to="armazem/analises/saidas"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Saídas
          </NavLink>
          <NavLink
            to="armazem/analises/pesagem-rodoviaria"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Pesagem rodoviária
          </NavLink>
          <NavLink
            to="armazem/analises/finalizados"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Finalizados
          </NavLink>
          <NavLink
            to="armazem/analises/isd"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Instruções de Serviços
          </NavLink>
          <NavLink
            to="armazem/analises/rastreio"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Rastreio de Lote
          </NavLink>
          <NavLink
            to="armazem/analises/servicos-armazem"
            className="menu_link"
            onClick={() => setShowMenu(false)}
            style={({ isActive }) => (isActive ? selected : undefined)}
          >
            Programação Armazéns
          </NavLink>
        </div>
      )}
    </ItemMenu>
  );
}
