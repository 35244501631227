import { useEffect, useState, useContext } from "react";

import { ChartClient } from "../chartClient";
import { ChartSacas } from "../chartSacas";

import { AuthContext } from "../../../../../../../contexts/Auth/AuthContext";
import { AnaliseContext } from "../../../../../../../contexts/Analise/AnaliseContext";

import { getSaidas } from "../../../../../../../services/api";

export function ChartsFaixa() {
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);
  const { date } = useContext(AnaliseContext);
  const [data, setData] = useState([]);

  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetch() {
      setError(false);
      try {
        const res = await getSaidas(
          dono,
          userAuth,
          password,
          codEmpresa,
          codFilial,
          "AG",
          date.dataAnaliseInicio,
          date.dataAnaliseFinal
        );
        setData(res);
      } catch (error) {
        console.log(error);
        setError(true);
      }
    }
    fetch();
  }, [dono, userAuth, password, codEmpresa, codFilial, date]);

  return (
    <div>
      <h3>Saídas por faixa</h3>
      <div className="charts">
        {error && <div style={{ color: "#FFF" }}>Erro ao carregar dados</div>}
        {data.length > 0 && !error && (
          <div className="charts-faixa">
            <div className="chart-client">
              <ChartClient dataApi={data} />
            </div>
            <div className="chart-sacas">
              <ChartSacas dataApi={data} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
