import React from "react";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  TimeScale,
  Title,
} from "chart.js";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  TimeScale,
  ChartDataLabels,
  Title
);

export default function ChartTotal({
  datasetGraphic,
  height,
  width,
  displayDataLabel,
  titleTooltip,
  responsive,
}) {
  const data = { labels: [], datasets: [] };

  return (
    <>
      <Chart
        height={height}
        width={width}
        title={titleTooltip}
        data={datasetGraphic || data}
        options={{
          scales: {
            x: {
              ticks: {
                callback: function (value) {
                  const characterLimit = 12;
                  const label = this.getLabelForValue(value);
                  if (label.length >= characterLimit) {
                    return (
                      label
                        .slice(0, label.length)
                        .substring(0, characterLimit - 1)
                        .trim() + "..."
                    );
                  }
                  return label;
                },
                color: "#fff",
              },
            },
            y: {
              ticks: {
                color: "#fff",
                callback: function (value) {
                  return value.toLocaleString("pt-BR"); // <-- formata o eixo Y
                },
              },
            },
          },
          responsive: !(responsive <= 1500),
          plugins: {
            title: {
              display: true,
              text: titleTooltip,
              color: "#fff",
            },
            legend: {
              position: "bottom",
              labels: {
                color: "#fff",
              },
            },
            datalabels: {
              display: displayDataLabel,
              color: "#fff",
              font: {
                weight: "bold",
              },
              formatter: function (value) {
                return value.toLocaleString("pt-BR"); // <-- formata datalabel
              },
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const value = tooltipItem.dataset.data[tooltipItem.dataIndex];

                  if (tooltipItem.dataset.totalSacas) {
                    const sacas =
                      tooltipItem.dataset.totalSacas[tooltipItem.dataIndex];
                    const pesagem =
                      tooltipItem.dataset.qtdPesagens[tooltipItem.dataIndex];
                    const peso = value.toLocaleString("pt-BR");

                    return `PESO: ${peso} | SACAS: ${sacas} | PESAGENS: ${pesagem}`;
                  } else {
                    return `${
                      tooltipItem.dataset.label
                    }: ${value.toLocaleString("pt-BR")}`;
                  }
                },
              },
            },
          },
        }}
      />
    </>
  );
}
