import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
`;

export const Button = styled.button`
  width: 100%;
  max-width: 150px;
  padding: 7px 0.25rem;
  background-color: var(--blue-a18);
  font-weight: bold;
  color: var(--blue-a1);
  border: none;
  border-radius: 0.25rem;
  transition: all 0.3s;
  border: 1px solid var(--blue-a18);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover:not(:disabled) {
    background-color: var(--blue-a13);
  }
`;

export const ButtonPDF = styled.button`
  width: 100%;
  max-width: 150px;
  padding: 7px 0.25rem;
  background-color: var(--blue-a18);
  font-weight: bold;
  color: var(--blue-a1);
  border: none;
  border-radius: 0.25rem;
  transition: all 0.3s;
  border: 1px solid var(--blue-a18);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover:not(:disabled) {
    background-color: var(--blue-a13);
  }
`;
