import { useMemo } from "react";
import ChartTotal from "../../../../../../../components/Charts/ChartArm/ChartAnalises/ChartTotal";

export function ChartDay({ widthSize, data }) {
  const dataSets = useMemo(() => {
    if (!data.length) return { datasets: [], labels: [] };

    const grouped = {};

    data.forEach((item) => {
      const date = item.DAT_SAIDA;

      if (!grouped[date]) {
        grouped[date] = { fisica: 0, juridica: 0 };
      }

      if (item.FLG_FJ === "F") {
        grouped[date].fisica += item.NUM_SACAS_CLIENTE || 0;
      } else if (item.FLG_FJ === "J") {
        grouped[date].juridica += item.NUM_SACAS_CLIENTE || 0;
      }
    });

    const labels = Object.keys(grouped).sort((a, b) => {
      const [dayA, monthA, yearA] = a.split("/").map(Number);
      const [dayB, monthB, yearB] = b.split("/").map(Number);
      return (
        new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB)
      );
    });

    const fisicaData = labels.map((date) => grouped[date].fisica);
    const juridicaData = labels.map((date) => grouped[date].juridica);

    const datasets = [
      {
        type: "bar",
        label: "Física",
        backgroundColor: "#047857",
        data: fisicaData,
      },
      {
        type: "bar",
        label: "Jurídica",
        backgroundColor: "#003d73",
        data: juridicaData,
      },
    ];

    return { datasets, labels };
  }, [data]);

  return (
    <ChartTotal
      datasetGraphic={dataSets}
      height="220"
      width="1280"
      titleTooltip="Diário"
      displayDataLabel={true}
      responsive={widthSize}
    />
  );
}
