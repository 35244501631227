import React, { useContext, useState, useEffect } from "react";
import { Container, customStylesSelect } from "./style";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import Warning from "../../../components/Layouts/Warning/Warning";
import Loading from "../../../components/Layouts/Loading/Loading";
import { OptionsSelect } from "./DataOptions/OptionsSelect";
import { fetchProdutores } from "./Functions/fetchProdutores";
import SelectGlobal from "../../../components/Layouts/Select/Select";
import bagCoffee from "../../../assets/Icons Relatorios/iconBagCoffee.svg";
import weightCoffee from "../../../assets/Icons Relatorios/iconWeightCoffee.svg";
import CardTotal from "./Components/CardTotal/CardTotal";
import RtExtratoCafe from "./Components/RtExtratoCafe";
import RtEstoqueFisico from "./Components/RtEstoqueFisico";
import ButtonReturnData from "./Components/ButtonReturnData/ButtonReturnData";
import RtEstoqueLote from "./Components/RtEstoqueLote";
import RtEstoqueAnalitico from "./Components/RtEstoqueAnalitico";
import RtEstoqueSintetico from "./Components/RtEstoqueSintetico";
import { TableExtratoCafe } from "./Components/Tables/TableExtratoCafe";
import { TableEstoqueLote } from "./Components/Tables/TableEstoqueLote";
import { TableEstoque } from "./Components/Tables/TableEstoque";
import { useLocation } from "react-router-dom";

const RelatoriosArm = () => {
  const { dono, userAuth, password } = useContext(AuthContext);

  const [dataExtratoProdutor, setDataExtratoProdutor] = useState("");
  const [produtores, setProdutores] = useState([]);
  const [api, setApi] = useState([]);
  const [relatorio, setRelatorio] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [total, setTotal] = useState("");

  const [codLote, setCodLote] = useState("");
  const [dateRange, setDateRange] = useState({});

  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  const title = {
    1: "Extrato de Café",
    2: "Estoque Físico",
    3: "Estoque Lote",
    4: "Estoque Analítico",
    5: "Estoque Sintético",
  };

  const location = useLocation();

  useEffect(() => {
    const pathToRelatorio = {
      "/armazem/relatorios/extrato-cafe": 1,
      "/armazem/relatorios/estoque-fisico": 2,
      "/armazem/relatorios/estoque-lote": 3,
      "/armazem/relatorios/estoque-analitico": 4,
      "/armazem/relatorios/estoque-sintetico": 5,
    };
    const reportId = pathToRelatorio[location.pathname] || "";
    setApi([]);
    setLoadingSelect(true);
    setError(false);
    setRelatorio(reportId);
  }, [location]);

  useEffect(() => {
    if (relatorio) {
      setError(false);
      setLoadingSelect(true);

      if (relatorio === 1 || relatorio === 2 || relatorio === 4) {
        async function fetchData() {
          try {
            const updatedProdutores = await fetchProdutores(
              dono,
              userAuth,
              password,
              relatorio
            );
            setProdutores(updatedProdutores);
            setLoadingSelect(false);
          } catch (err) {
            console.log(err);
            setError(true);
            setLoadingSelect(false);
          }
        }
        fetchData();
      } else {
        setLoadingSelect(false);
      }
    }
  }, [dono, userAuth, password, relatorio]);

  function handleDatePicker(date) {
    if (date) {
      const newDate = new Intl.DateTimeFormat("pt-BR").format(date);
      setDataExtratoProdutor(newDate);
    }
  }

  function handleDatePickerRange(date) {
    setDateRange(date);
  }

  // function handleSelect(selectedOptions) {
  //   setApi([]);
  //   setLoadingSelect(true);
  //   setError(false);
  //   setRelatorio(selectedOptions.id);
  // }

  function handleSelectProdutor(selectedOptions) {
    setApi([]);
    setError(false);
    setLoadingTable(false);
    setMsg();
    if (selectedOptions !== null) {
      setCpfCnpj(selectedOptions.cpf);
    }
  }

  function handleInput(event) {
    setCodLote(event.target.value);
  }

  return (
    <Container>
      <div className="selectRelatorios">
        <div className="selectOptions">
          {/* <SelectGlobal
            styles={customStylesSelect}
            options={OptionsSelect[0].analise}
            onChange={handleSelect}
          /> */}
          {loadingSelect && (
            <div className="warningOrLoading">
              <Loading />
            </div>
          )}
          {relatorio && !loadingSelect && <h3> {title[relatorio]} </h3>}
          {relatorio && !loadingSelect && (
            <div className="opcoesProdutores">
              {relatorio === 1 && (
                <RtExtratoCafe
                  produtores={produtores[0].analise}
                  handleSelectProdutor={handleSelectProdutor}
                  handleDatePicker={handleDatePicker}
                />
              )}
              {relatorio === 2 && (
                <RtEstoqueFisico
                  produtores={produtores[0].analise}
                  handleSelectProdutor={handleSelectProdutor}
                />
              )}
              {relatorio === 3 && (
                <RtEstoqueLote
                  handleSelectProdutor={handleSelectProdutor}
                  handleDatePicker={handleDatePickerRange}
                  onChange={handleInput}
                />
              )}
              {relatorio === 4 && (
                <RtEstoqueAnalitico
                  produtores={produtores[0].analise}
                  handleSelectProdutor={handleSelectProdutor}
                  handleDatePicker={handleDatePickerRange}
                />
              )}
              {relatorio === 5 && (
                <RtEstoqueSintetico handleDatePicker={handleDatePickerRange} />
              )}

              <div className="dateButtonRelatorioFin">
                <ButtonReturnData
                  relatorio={relatorio}
                  data={dataExtratoProdutor}
                  setError={setError}
                  setLoadingTable={setLoadingTable}
                  setApi={setApi}
                  setTotal={setTotal}
                  cpfCnpj={cpfCnpj}
                  setMsg={setMsg}
                  codLote={codLote}
                  dateRange={dateRange}
                />
              </div>
            </div>
          )}
        </div>

        {api.length > 0 && (relatorio === 1 || relatorio === 2) && (
          <div className="cardsTotal">
            <CardTotal
              imgCardSacas={bagCoffee}
              imgCardPeso={weightCoffee}
              total={total}
            />
          </div>
        )}
      </div>
      <div className="containerTable">
        {loadingTable && (
          <div className="LoadingMsgTable">
            <div className="warningOrLoading">
              <Loading />
            </div>
          </div>
        )}
        {api.length > 0 && (
          <div className="tableRelatorioExtratoCafe">
            {[1, 2].includes(relatorio) && (
              <TableExtratoCafe
                dataTable={api}
                infoRelatorio={relatorio}
                codLote={codLote}
              />
            )}
            {relatorio === 3 && (
              <TableEstoqueLote dataTable={api} infoRelatorio={relatorio} />
            )}
            {[4, 5].includes(relatorio) && (
              <TableEstoque dataTable={api} infoRelatorio={relatorio} />
            )}
          </div>
        )}
        {api.length <= 0 && !loadingTable && (
          <div className="LoadingMsgTable">
            <h3>{msg}</h3>
          </div>
        )}
        {error && <Warning label={msg} className={"warningOrLoading"} />}
      </div>
    </Container>
  );
};

export default RelatoriosArm;
