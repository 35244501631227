import { useState, useMemo, useCallback } from "react";
import moment from "moment";
import {
  Container,
  Empty,
  HeaderSection,
  Table,
  TableSearch,
  TableBottom,
} from "./style";
import { TableHead } from "./TableHead";
import {
  calcularSaldoPesoTotal,
  calcularSaldoSacasTotal,
  generateId,
} from "../../../../../../../utils/functions";
import { ListFilter } from "lucide-react";

export function TableLotes({ data }) {
  const [colunaOrdenada, setColunaOrdenada] = useState(null);
  const [direcao, setDirecao] = useState("asc");
  const [termoBusca, setTermoBusca] = useState(""); // Estado para a busca

  // Memoiza o processamento dos registros
  const registrosProcessados = useMemo(() => {
    return data.registros.map((registro) => ({
      ...registro,
      movimento: "X",
      id: generateId(),
    }));
  }, [data.registros]);

  // Memoiza a filtragem dos registros
  const registrosFiltrados = useMemo(() => {
    return termoBusca.trim() !== ""
      ? registrosProcessados.filter((item) =>
          item.movimento.toLowerCase().includes(termoBusca.toLowerCase())
        )
      : registrosProcessados;
  }, [termoBusca, registrosProcessados]);

  // Memoiza a ordenação dos registros
  const registrosOrdenados = useMemo(() => {
    if (!colunaOrdenada) return registrosFiltrados;

    return [...registrosFiltrados].sort((a, b) => {
      let valorA = a[colunaOrdenada];
      let valorB = b[colunaOrdenada];

      if (colunaOrdenada === "DAT_ENTRADA") {
        valorA = moment(valorA, "YYYY-MM-DD").valueOf();
        valorB = moment(valorB, "YYYY-MM-DD").valueOf();
      }

      // Se os valores forem null ou undefined, tratamos como string vazia para evitar erro no localeCompare
      valorA = valorA ?? "";
      valorB = valorB ?? "";

      if (typeof valorA === "number" && typeof valorB === "number") {
        return direcao === "asc" ? valorA - valorB : valorB - valorA;
      }

      return direcao === "asc"
        ? String(valorA).localeCompare(String(valorB))
        : String(valorB).localeCompare(String(valorA));
    });
  }, [colunaOrdenada, direcao, registrosFiltrados]);

  // Memoiza a função de ordenação para evitar recriação desnecessária
  const ordenarPor = useCallback(
    (coluna) => {
      if (colunaOrdenada === coluna) {
        setDirecao(direcao === "asc" ? "desc" : "asc");
      } else {
        setColunaOrdenada(coluna);
        setDirecao("asc");
      }
    },
    [colunaOrdenada, direcao]
  );
  // 🔹 Agrupar registros por embalagem
  const { totaisSaldo } = useMemo(() => {
    return data.registros.reduce(
      (acc, registro) => {
        const sacas = registro.NUM_SACAS || 0;
        const peso = registro.NUM_PESO || 0;

        acc.totaisEntrada.sacas += sacas;
        acc.totaisEntrada.peso += peso;

        return acc;
      },
      { totaisEntrada: {}, totaisEmbarque: {} }
    );
  }, [data.registros]);

  return (
    <Container>
      <HeaderSection>
        <h2>{data.NOM_FAZE}</h2>
        {/* Input de busca */}
        <TableSearch>
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Filtrar por movimento"
            value={termoBusca}
            onChange={(e) => setTermoBusca(e.target.value)}
          />
          <ListFilter size={20} />
        </TableSearch>
      </HeaderSection>

      {registrosOrdenados.length === 0 && <Empty>SALDO ZERADO</Empty>}

      {registrosOrdenados.length > 0 && (
        <>
          <Table>
            <TableHead
              ordenarPor={ordenarPor}
              colunaOrdenada={colunaOrdenada}
              direcao={direcao}
            />
            <tbody>
              {registrosOrdenados.map((item) => (
                <tr key={item.id}>
                  {item.NUM_NOTA ? (
                    <td>
                      {`${item.NUM_NOTA}/${
                        item.NOM_SERIE ? item.NOM_SERIE : "000"
                      }`}
                    </td>
                  ) : (
                    <td>{item.NOM_SERIE ? item.NOM_SERIE : ""}</td>
                  )}
                  <td>{item.COD_LOTE}</td>
                  <td>{item.NUM_CONTROLE_EMPRESA}</td>
                  <td>{item.NUM_SACAS.toLocaleString("pt-BR")}</td>
                  <td>
                    {item.NUM_PESO.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td>{moment(item.DAT_ENTRADA).format("DD/MM/YYYY")}</td>
                  <td>{item.NOM_PADRAO}</td>
                  <td>{item.NOM_BEBIDA}</td>
                  <td>{item.NUM_CATACAO}</td>
                  <td>{item.NUM_SECA}</td>
                  <td>{item.NUM_DEFEITO}</td>
                  <td>{item.NOM_CERTIFICADO}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <TableBottom>
            <div className="atual">
              <h2>SALDO DO PRODUTOR (SEM CONSIDERAR SALDO EM GUIA)</h2>
            </div>
            <div>
              <p>
                {calcularSaldoSacasTotal(data.registros, "X").toLocaleString(
                  "pt-BR"
                )}{" "}
                sacas /{" "}
                {calcularSaldoPesoTotal(data.registros, "X").toLocaleString(
                  "pt-BR"
                )}{" "}
                kgs
              </p>
            </div>
          </TableBottom>
        </>
      )}
    </Container>
  );
}
