import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-size: 0.75rem;

  .filter-footer {
    padding: 4px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;

    .filtro-armazens {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      label {
        margin-left: 8px;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        cursor: pointer;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 4px 8px;
  border: none;
  border-radius: 0.25rem;
  color: ${({ variant }) =>
    variant === "ghost" ? "var(--blue-a18)" : "var(--blue-a1)"};
  background-color: ${({ variant }) =>
    variant === "ghost" ? "transparent" : "var(--blue-a18)"};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ variant }) =>
      variant === "ghost" ? "var(--blue-a1)" : "var(--blue-a11)"};
  }
`;

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 2px 8px;
  border-radius: 0.5rem;
  color: var(--blue-a18);
  background-color: var(--blue-a1);

  button {
    border: none;
    padding: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      background-color: var(--blue-a4);
    }
  }
`;

export const StyledOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const StyledContent = styled(Dialog.Content)`
  background-color: var(--blue-a18);
  border-radius: 6px;
  box-shadow: var(--shadow-6);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 90vh;
  overflow: auto;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }

  .filtros {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    font-weight: 500;
    color: var(--blue-a1);

    .dubble {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    label {
      width: 100%;
      display: flex;
      flex-direction: column;

      .input-dubble {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    input {
      width: 100%;
      padding: 4px;
      border-radius: 0.25rem;
      border: 1px solid var(--blue-a18);
      outline: none;
      background-color: var(--gray-c5);
      color: var(--blue-a1);
      transition: all 0.2s;

      &::placeholder {
        color: var(--blue-a3); /* Altere para a cor desejada */
        opacity: 0.7; /* Ajuste a opacidade, se necessário */
      }

      &:focus {
        border: 1px solid transparent;
        border-bottom: 1px solid var(--blue-emsoft);
      }
    }
  }
`;

export const StyledTitle = styled(Dialog.Title)`
  margin: 0;
  font-weight: 500;
  color: var(--blue-a1);
  font-size: 17px;
`;

export const StyledDescription = styled(Dialog.Description)`
  margin: 10px 0 20px;
  color: var(--gray-c2);
  font-size: 15px;
  line-height: 1.5;
`;

export const CloseButton = styled(Dialog.Close)`
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--gray-c2);
  cursor: pointer;

  &:hover {
    background-color: var(--gray-c3);
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--blue-emsoft);
  }
`;
