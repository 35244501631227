import { ArrowDownUp, MoveDown, MoveUp } from "lucide-react";

export function TableHead({ ordenarPor, colunaOrdenada, direcao }) {
  return (
    <thead>
      <tr>
        <th onClick={() => ordenarPor("movimento")}>
          Tipo{" "}
          {colunaOrdenada === "movimento" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_DOC")}>
          Documento{" "}
          {colunaOrdenada === "NUM_DOC" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_DOC")}>
          NF/Serie{" "}
          {colunaOrdenada === "NUM_DOC" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>

        <th onClick={() => ordenarPor("NUM_DOC")}>
          Ref. Cliente - OS{" "}
          {colunaOrdenada === "NUM_DOC" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>

        <th onClick={() => ordenarPor("NOM_REM")}>
          Remetente/Destinatário{" "}
          {colunaOrdenada === "NOM_REM" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>

        <th onClick={() => ordenarPor("DAT_OPERACAO")}>
          Data{" "}
          {colunaOrdenada === "DAT_OPERACAO" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("COD_LOTE")}>
          Lote{" "}
          {colunaOrdenada === "COD_LOTE" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_SACAS")}>
          Sacas{" "}
          {colunaOrdenada === "NUM_SACAS" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_PESO")}>
          Peso{" "}
          {colunaOrdenada === "NUM_PESO" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NOM_EMBALAGEM")}>
          Embalagem{" "}
          {colunaOrdenada === "NOM_EMBALAGEM" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
      </tr>
    </thead>
  );
}
