import { useState, useContext, useEffect } from "react";

import { Container } from "./style";

import { useGraphicResponsive } from "../../../hooks/useGraphicResponsive";

import { AnaliseContext } from "../../../../../../contexts/Analise/AnaliseContext";
import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";

import {
  dayTodayFormated,
  getFirstDayOfYear,
} from "../../../../../../utils/dates";

import { getSaidas } from "../../../../../../services/api";

import { ChartDay } from "./chartDay";
import { ChartMonth } from "./chartMonth";
import { ChartYear } from "./chartYear";
import { ChartsFaixa } from "./chartsFaixa";

export function Charts({ data }) {
  const [dataFullYear, setDataFullYear] = useState([]);

  const { date } = useContext(AnaliseContext);
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  const widthSize = useGraphicResponsive();

  const firstDayYear = getFirstDayOfYear(date.dataAnaliseInicio);

  useEffect(() => {
    async function fetch() {
      try {
        const res = await getSaidas(
          dono,
          userAuth,
          password,
          codEmpresa,
          codFilial,
          "DMA",
          firstDayYear,
          dayTodayFormated
        );
        setDataFullYear(res);
      } catch (error) {
        console.log(error);
      }
    }
    fetch();
  }, [dono, userAuth, password, codEmpresa, codFilial, date, firstDayYear]);

  return (
    <Container>
      <div>
        <h3>Relatório de saídas</h3>
        <div className="charts">
          <div className="chart-day">
            <ChartDay data={data} widthSize={widthSize} />
          </div>
          <div className="dubble-charts">
            <div className="chart-month">
              <ChartMonth widthSize={widthSize} data={dataFullYear} />
            </div>
            <div className="chart-year">
              <ChartYear widthSize={widthSize} data={dataFullYear} />
            </div>
          </div>
        </div>
      </div>
      <ChartsFaixa />
    </Container>
  );
}
