import { useState, useContext, useEffect } from "react";

import { AuthContext } from "../../../../../../contexts/Auth/AuthContext";
import { AnaliseContext } from "../../../../../../contexts/Analise/AnaliseContext";

import { useGraphicResponsive } from "../../../hooks/useGraphicResponsive";

import { getDataAnalisePesagem } from "../../../../../../services/api";

import {
  dayTodayFormated,
  getFirstDayOfYear,
} from "../../../../../../utils/dates.js";

import { Container } from "./style";

import { ChartDay } from "./chartDay";
import { ChartMonth } from "./chartMonth";
import { ChartYear } from "./chartYear";

export function SacasControle({ data }) {
  const [dataFullYear, setDataFullYear] = useState([]);

  const { date } = useContext(AnaliseContext);
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);

  const widthSize = useGraphicResponsive();

  const firstDayYear = getFirstDayOfYear(date.dataAnaliseInicio);

  useEffect(() => {
    async function fetchPesagem() {
      const response = await getDataAnalisePesagem(
        dono,
        userAuth,
        password,
        codEmpresa,
        codFilial,
        "DMAH",
        firstDayYear,
        dayTodayFormated
      );

      setDataFullYear(response);
    }
    fetchPesagem();
  }, [date, firstDayYear, dono, userAuth, password, codEmpresa, codFilial]);

  return (
    <Container>
      <h3>Sacas por controle</h3>
      <div className="charts">
        <div className="chart-day">
          <ChartDay data={data} widthSize={widthSize} />
        </div>
        <div className="dubble-charts">
          <div className="chart-month">
            <ChartMonth widthSize={widthSize} data={dataFullYear} />
          </div>
          <div className="chart-year">
            <ChartYear widthSize={widthSize} data={dataFullYear} />
          </div>
        </div>
      </div>
    </Container>
  );
}
