import { ArrowDownUp, MoveDown, MoveUp } from "lucide-react";

export function TableHead({ ordenarPor, colunaOrdenada, direcao }) {
  return (
    <thead>
      <tr>
        <th onClick={() => ordenarPor("NUM_DOC")}>
          NF/Serie{" "}
          {colunaOrdenada === "NUM_DOC" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("COD_LOTE")}>
          Lote{" "}
          {colunaOrdenada === "COD_LOTE" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_CONTROLE_EMPRESA")}>
          Ref. Cliente{" "}
          {colunaOrdenada === "NUM_CONTROLE_EMPRESA" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_SACAS")}>
          Sacas{" "}
          {colunaOrdenada === "NUM_SACAS" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_PESO")}>
          Peso{" "}
          {colunaOrdenada === "NUM_PESO" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("DAT_ENTRADA")}>
          Data{" "}
          {colunaOrdenada === "DAT_ENTRADA" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NOM_PADRAO")}>
          Padrão{" "}
          {colunaOrdenada === "NOM_PADRAO" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NOM_BEBIDA")}>
          Bebida{" "}
          {colunaOrdenada === "NOM_BEBIDA" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_CATACAO")}>
          Catação{" "}
          {colunaOrdenada === "NUM_CATACAO" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_SECA")}>
          T.U.{" "}
          {colunaOrdenada === "NUM_SECA" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NUM_DEFEITO")}>
          Defeito{" "}
          {colunaOrdenada === "NUM_DEFEITO" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
        <th onClick={() => ordenarPor("NOM_CERTIFICADO")}>
          Certificação{" "}
          {colunaOrdenada === "NOM_CERTIFICADO" ? (
            direcao === "asc" ? (
              <MoveUp size={14} />
            ) : (
              <MoveDown size={14} />
            )
          ) : (
            <ArrowDownUp size={14} />
          )}
        </th>
      </tr>
    </thead>
  );
}
