export const CargaDescarga = [
  {
    Header: "Pessoa",
    accessor: "FLG_FJ",
    Cell: ({ cell: { value } }) => {
      if (value === "J") {
        return (
          <p
            style={{
              color: "#003d73",
              fontWeight: "bold",
            }}
          >
            JURÍDICA
          </p>
        );
      }
      if (value === "F") {
        return (
          <p
            style={{
              color: "#047857",
              fontWeight: "bold",
            }}
          >
            FÍSICA
          </p>
        );
      }

      return "OUTRO";
    },
  },
  {
    Header: "Nome",
    accessor: "NOM_CADASTRO",
  },
  {
    Header: "Qtd. Sacas",
    accessor: "NUM_SACAS_CLIENTE",
  },
  {
    Header: "Qtd. Documentos",
    accessor: "NUM_DOCS_CLIENTE",
  },
  {
    Header: "Data",
    accessor: "DAT_SAIDA",
  },
];
