import { useState, useEffect, useContext, useMemo } from "react";
import { Container, Empty, HeaderSection, Table, TableBottom } from "./style";
import { TableHead } from "./TableHead";
import { getBoletimLotesISD } from "../../../../../../../services/api";
import { AuthContext } from "../../../../../../../contexts/Auth/AuthContext";
import { SelectedContext } from "../../../Context/SelectedProdutor/SelectedContext";
import Loading from "../../../../../../../components/Layouts/Loading/Loading";

export function TableLotesGuias() {
  const { dono, userAuth, password, codEmpresa } = useContext(AuthContext);
  const { endDate, produtorSelected } = useContext(SelectedContext);
  const [dataLotesGuias, setdataLotesGuias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { totaisEntrada } = useMemo(() => {
    return dataLotesGuias.reduce(
      (acc, registro) => {
        const sacasEntrada = registro.VAL_SALDO_SACAS || 0;
        const pesoEntrada = registro.VAL_SALDO_PESO || 0;

        acc.totaisEntrada.sacas += sacasEntrada;
        acc.totaisEntrada.peso += pesoEntrada;

        return acc;
      },
      {
        totaisEntrada: { sacas: 0, peso: 0 }, // 🔹 Inicialização correta
      }
    );
  }, [dataLotesGuias]);

  useEffect(() => {
    async function fetchDataLotesGuias() {
      setLoading(true);
      try {
        const res = await getBoletimLotesISD(
          dono,
          userAuth,
          password,
          codEmpresa,
          produtorSelected ? produtorSelected.cpf : "",
          endDate
        );

        // Ordenar os dados pelo campo NUM_IS em ordem crescente
        const dadosOrdenados = res.sort((a, b) => a.NUM_IS - b.NUM_IS);

        setdataLotesGuias(dadosOrdenados);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchDataLotesGuias();
  }, [dono, userAuth, password, codEmpresa, produtorSelected, endDate]);

  return (
    <Container>
      {loading && <Loading />}
      {error && <div>Erro ao carregar lotes das guias.</div>}

      {!loading && dataLotesGuias.length === 0 && (
        <Empty>Nenhum lote em guia.</Empty>
      )}
      {!loading && dataLotesGuias.length > 0 && (
        <>
          <HeaderSection>
            <h2>LOTES EM GUIAS</h2>
          </HeaderSection>
          <Table>
            <TableHead />
            <tbody>
              {dataLotesGuias.map((item) => (
                <tr key={item.id}>
                  <td>{item.NUM_IS}</td>
                  <td>{item.NUM_LOTE}</td>
                  <td>{item.VAL_SALDO_SACAS.toLocaleString("pt-BR")}</td>
                  <td>
                    {item.VAL_SALDO_PESO.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <TableBottom>
            <div className="atual">
              <p>
                Total: {totaisEntrada.sacas.toLocaleString("pt-BR")} sacas /{" "}
                {totaisEntrada.peso.toLocaleString("pt-BR")} kgs
              </p>
            </div>
          </TableBottom>
        </>
      )}
    </Container>
  );
}
