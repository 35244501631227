import { Container } from "./style";

export function ModalContent({ documento }) {
  const operadores = documento.S_OPERADOR
    ? documento.S_OPERADOR.split(";")
    : [];

  const conclusao =
    documento.N_BAGS_TOTAL && documento.N_BAGS_TOTAL > 0
      ? `${Math.round(
          ((documento.N_BAGS_TOTAL - documento.N_BAGS_PEND_TOTAL) /
            documento.N_BAGS_TOTAL) *
            100
        )}%`
      : "0%";

  const embalagem = documento.S_EMBALAGEM
    ? documento.S_EMBALAGEM.split(";")
    : [];

  return (
    <Container>
      <div className="details">
        <p className="label">Cliente</p>
        <p>{documento.S_NOM_CADASTRO}</p>
      </div>
      <div className="details">
        <p className="label">Serviço</p>
        <p>{documento.S_SERVICO}</p>
      </div>
      <div className="details">
        <p className="label">Sacas total</p>
        <p>{documento.N_SACAS_TOTAL.toLocaleString("pt-BR")}</p>
      </div>
      <div className="details">
        <p className="label">Remoção total</p>
        <p>{documento.N_REMOCAO_TOTAL}</p>
      </div>
      <div className="details">
        <p className="label">Total de Bag's</p>
        <p>{documento.N_BAGS_TOTAL}</p>
      </div>
      <div className="details">
        <p className="label">Total de Bag's pendentes</p>
        <p>{documento.N_BAGS_PEND_TOTAL}</p>
      </div>
      <div className="details">
        <p className="label">Status</p>
        <p>{documento.S_STATUS}</p>
      </div>
      <div className="details last">
        <p className="label">Conclusão</p>
        <p>{conclusao}</p>
      </div>
      {operadores.length > 0 && (
        <div className="wrapperOpe">
          <p>Operadores</p>
          <div>
            {operadores.map((operador, index) => (
              <p key={index} className="label">
                {operador}
              </p>
            ))}
          </div>
        </div>
      )}

      {embalagem.length > 0 && (
        <div className="wrapperOpe">
          <p>Embalagens</p>
          <div>
            {embalagem.map((operador, index) => (
              <p key={index} className="label">
                {operador}
              </p>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
}
