export function TableHead({ ordenarPor, colunaOrdenada, direcao }) {
  return (
    <thead>
      <tr>
        <th onClick={() => ordenarPor("NUM_IS")}>Guia</th>
        <th onClick={() => ordenarPor("NUM_LOTE")}>Lote</th>
        <th onClick={() => ordenarPor("VAL_SALDO_SACAS")}>Sacas</th>
        <th onClick={() => ordenarPor("VAL_SALDO_PESO")}>Peso </th>
      </tr>
    </thead>
  );
}
