import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { AnaliseContext } from "../../../../contexts/Analise/AnaliseContext";

import { BriefcaseBusiness, Calculator, UserRound } from "lucide-react";

import { Cards, Container } from "./style";

import { Card } from "./components/Card";
import { getEntradas } from "../../../../services/api";
import Loading from "../../../../components/Layouts/Loading/Loading";
import { HeaderAnalise } from "../components/HeaderAnalise";
import { Charts } from "./components/Charts";
import { Table } from "./components/table";

export function Entradas() {
  const { dono, userAuth, password, codEmpresa, codFilial } =
    useContext(AuthContext);
  const { date } = useContext(AnaliseContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      setError(false);
      try {
        const res = await getEntradas(
          dono,
          userAuth,
          password,
          codEmpresa,
          codFilial,
          "DMA",
          date.dataAnaliseInicio,
          date.dataAnaliseFinal
        );
        setData(res);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, [dono, userAuth, password, codEmpresa, codFilial, date]);

  const pessoasFisica = data.filter((item) => item.FLG_FJ === "F");
  const pessoasJuridica = data.filter((item) => item.FLG_FJ === "J");

  function totalSacas(arrayTipoPessoa) {
    const result = arrayTipoPessoa.reduce(
      (acc, item) => (acc += item.NUM_SACAS_CLIENTE),
      0
    );
    return result;
  }

  function totalDocs(arrayTipoPessoa) {
    const result = arrayTipoPessoa.reduce(
      (acc, item) => (acc += item.NUM_DOCS_CLIENTE),
      0
    );
    return result;
  }

  const sacasTotalFisica = totalSacas(pessoasFisica);
  const sacasTotalJuridica = totalSacas(pessoasJuridica);
  const sacasTotalPessoas = sacasTotalFisica + sacasTotalJuridica;
  const totalDocsFisica = totalDocs(pessoasFisica);
  const totalDocsJuridica = totalDocs(pessoasJuridica);
  const totalDocsPessoas = totalDocsFisica + totalDocsJuridica;

  function percentPessoas(totalPessoas, totalValue) {
    const percent = (totalPessoas / totalValue) * 100;
    return percent.toFixed(1).replace(".", ",");
  }

  return (
    <Container>
      {loading && <Loading />}
      {error && <div>Erro ao tentar carregar os dados!</div>}
      {data.length === 0 && !loading && (
        <div>Não há dados para o período informado!</div>
      )}
      {data.length > 0 && !loading && !error && (
        <>
          <HeaderAnalise title="Entradas (Pessoas Físicas e Jurídicas)" />
          <Cards>
            <Card
              bgColor="#030F14"
              title="Pessoas Físicas"
              valueSacas={sacasTotalFisica}
              valueDocs={totalDocsFisica}
              valuePercentSacas={percentPessoas(
                sacasTotalFisica,
                sacasTotalPessoas
              )}
              valuePercentDocs={percentPessoas(
                totalDocsFisica,
                totalDocsPessoas
              )}
            >
              <span>
                <UserRound size={24} color="#FFF" />
              </span>
            </Card>
            <Card
              bgColor="#083D61"
              title="Pessoas Jurídicas"
              valueSacas={sacasTotalJuridica}
              valueDocs={totalDocsJuridica}
              valuePercentSacas={percentPessoas(
                sacasTotalJuridica,
                sacasTotalPessoas
              )}
              valuePercentDocs={percentPessoas(
                totalDocsJuridica,
                totalDocsPessoas
              )}
            >
              <span>
                <BriefcaseBusiness size={24} color="#FFF" />
              </span>
            </Card>
            <Card
              bgColor="#009FE3"
              title="Total"
              valueSacas={sacasTotalPessoas}
              valueDocs={totalDocsPessoas}
              valuePercentSacas={100}
              valuePercentDocs={100}
            >
              <span>
                <Calculator size={24} color="#FFF" />
              </span>
            </Card>
          </Cards>
          <Charts data={data} />
          <Table dataTable={data} />
        </>
      )}
    </Container>
  );
}
