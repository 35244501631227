export function TableHead() {
  return (
    <thead>
      <tr>
        <th>Serviço</th>
        <th>Documento</th>
        <th>Cliente</th>
        <th>Sacas</th>
        <th>Remoção</th>
        <th>Total de Bag's</th>
        <th>Total de Bag's Pendentes</th>
        <th>Embalagem</th>
        <th>Operador</th>
        <th>Status</th>
        <th>Conclusão</th>
        <th></th>
      </tr>
    </thead>
  );
}
