import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  /* min-width: 800px; */
  overflow: auto;
  flex-direction: column;
`;

export const HeaderSection = styled.header`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--blue-a18);
  border-bottom: none;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: var(--blue-a1);
  background-color: var(--blue-a18);
  padding: 4px 12px;

  h2 {
    font-weight: 400;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #595959;
    text-align: center;
    padding: 2px;
  }

  th {
    background-color: var(--gray-c2);
  }

  td {
    text-transform: uppercase;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
`;

export const Empty = styled.div`
  width: 100%;
  text-align: center;
  border: 1px solid black;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`;

export const Tag = styled.div`
  display: flex;
  align-items: flex-end;
  background: ${({ $bgColor }) => $bgColor || "#fff"};
  gap: 0.5rem;
  border-radius: 0.75rem;
  padding: 0px 18px;

  .small {
    font-size: 0.75rem; /* Texto pequeno */
    margin-bottom: 0.25rem;
  }
`;

export const TableBottom = styled.div`
  background-color: var(--blue-a18);
  border: 1px solid var(--blue-a18);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: var(--blue-a1);
  padding: 4px 0px 0px;
  overflow: hidden;

  div {
    padding: 0 12px;
    border-bottom: 1px solid var(--blue-a15);
  }

  .atual {
    background-color: var(--blue-a11);
    border: none;
    font-size: 22px;
    text-align: center;
  }

  .totais-embalagens {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
  }
`;

export const TableSearch = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  background-color: var(--blue-a3);
  border-radius: 0.75rem;
  width: 260px;
  border: 2px solid transparent;
  transition: border 0.2s ease-in-out;

  /* Aplica a borda azul na div quando o input estiver em foco ou preenchido */
  &:focus-within,
  &:has(input:not(:placeholder-shown)) {
    border: 2px solid var(--blue-emsoft);
  }

  /* Muda a cor do ícone dentro da div quando o input estiver focado ou preenchido */
  &:focus-within svg,
  &:has(input:not(:placeholder-shown)) svg {
    color: var(--blue-emsoft);
  }

  input {
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    color: #01121f; /* Mantém a cor original do texto */
  }

  /* Estiliza o ícone do Lucide */
  svg {
    color: #01121f; /* Cor padrão */
    transition: color 0.2s ease-in-out;
  }
`;
